import { combineReducers,configureStore } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import persistStore from "redux-persist/es/persistStore";
import storage from "redux-persist/lib/storage";
import authSlice from './auth-slice'
import profileSlice from "./profile-slice";
import cookieSlice from "./cookie-slice";

const persistConfig = {
    key: 'ninja-van-partner-root',
    storage
}

const rootReducer = combineReducers({
    auth: authSlice,
    profile: profileSlice,
    cookie: cookieSlice
})

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
    reducer: persistedReducer,
    middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
})

const persistor = persistStore(store)
export { persistor }
export default store