import { Image } from 'antd';
import QRCode from 'qrcode.react';
import React from "react";

const containerW = '3in'
const containerH = '9in'

const childContainerW = '2.9in'
const childContainerH = '6in'

const childContainerHalfW = '1.45in'
const logo = require('../../asset/awb-logo.png');
class AirWayBillSlip extends React.Component {

    render() {
        const { senderName, senderPhone, senderAddress, receiverName, receiverPhone, receiverAddress, trackId, createDate, cod, sortingCode, printRef, note, parcelAmount, deliveryCharges, storeName } = this.props
        let localRef = printRef
        const customSizeStyles = {
            width: containerW,
            height: containerH,
            fontFamily: 'Arial, sans-serif',
            paddingLeft: '0.08in',
            paddingTop: '0.18in',
            // backgroundColor: "yellow"
        };

        const slipContainer = {
            width: childContainerW,
            height: childContainerH,
            marginTop: '1in',
            marginBottom: '1in',
            // backgroundColor: 'gray'
        }

        const lableStyles = {
            fontSize: '14px',
            // fontWeight: '600'
            // marginTop: 4,
            // marginBottom: 3
        };
        const senderStyles = {
            fontSize: '14px',
            // marginBottom: 2,
        };

        const phoneStyles = {
            fontSize: '14px'
        }
        const senderAddressStyles = {
            fontSize: '14px',
            width: '2.8in'
        }

        const feesStyles = {
            fontSize: '12px',
            fontWeight: '600',

        }

        const titleStyle = {
            fontSize: '30px',
            fontWeight: '700',
        }

        const firstCellStyle = {
            width: '1.87in',
            height: '1.7in',
            display: 'flex',
            // alignItems: 'center',
            paddingLeft: 5
        }

        const receiverCell = {
            width: childContainerW,
            height: '1in',
            display: 'flex',
            alignItems: 'center',
            paddingLeft: 5,
            paddingRight: 5
        }

        const codStyle = {
            fontSize: '18px',
            fontWeight: '700'
        }
        const sortingStyle = {
            fontSize: '27px',
            fontWeight: '800'
        }
        const trackIdStyle = {
            fontSize: '13px',
            fontWeight: '500'
        }
        const noteStyle = {
            fontSize: '13px',
            fontWeight: '500'
        }
        const dateStyle = {
            fontSize: 13, fontWeight: '500'
        }
        return (
            <div
                key={this.props.key}
                style={customSizeStyles}>
                <div
                    style={slipContainer}>
                    <div style={{
                        width: childContainerW,
                        height: '1.7in',
                        borderBottom: '1px dotted #000000',
                        display: 'flex'
                    }}>
                        <div style={firstCellStyle}>
                            <div>
                                <div>
                                    <Image
                                        preview={false}
                                        style={{ width: 160, marginTop: 2 }}
                                        src={logo} />
                                    {/* <div style={titleStyle}>ninjavan</div> */}
                                </div>

                                <div style={lableStyles}>From ( Sender )</div>
                                <div style={senderStyles}>{senderName}</div>
                                <div style={phoneStyles}>{senderPhone}</div>
                                <div style={{ ...senderAddressStyles, marginTop: 10 }}>{senderAddress}</div>
                            </div>

                        </div>
                        <div
                            style={{
                                width: '1in',
                                height: '1.1in',
                                display: 'flex',
                                justifyContent: 'center',
                                paddingTop: '0.05in'

                            }}>
                            <QRCode
                                style={{
                                    width: '1in',
                                    height: '1in',
                                }}
                                value={trackId} />
                        </div>
                    </div>
                    <div style={{
                        width: childContainerW,
                        height: '1in',
                        borderBottom: '1px dotted #000000',
                        display: 'flex',
                    }}>
                        <div style={receiverCell}>
                            <div>
                                <div style={lableStyles}>To ( Receiver )</div>
                                <div style={senderStyles}>{receiverName}&nbsp;-&nbsp;{receiverPhone}</div>
                                <div style={senderAddressStyles}>{receiverAddress}</div>
                            </div>

                        </div>
                        {/* <div
                            style={{
                                width: '1.2in',
                                height: '1.1in',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}>
                            <div>
                                <div style={lableStyles}>Create Date</div>
                                <div style={lableStyles}>{createDate}</div>
                            </div>
                        </div> */}
                    </div>
                    {/* <div style={{
                        height: '0.3in',
                        borderBottom: '1px dotted #000000',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}>
                        
                    </div>
                    <div style={{
                        height: '0.3in',
                        borderBottom: '1px dotted #000000',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}>
                       
                    </div> */}




                    {/* <div style={{
                        height: '0.3in',
                        borderBottom: '1px dotted #000000',
                        display: 'flex',
                        justifyContent: 'center',
                    }}>
                        <div style={{
                            height: '0.3in',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}>
                            <span style={codStyle}>Delivery Fees - {deliveryCharges ? deliveryCharges : 0} </span>
                        </div>


                    </div> */}
                    <div style={{
                        height: '0.35in',
                        borderBottom: '1px dotted #000000',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}>
                        <span style={sortingStyle}>{sortingCode}</span>
                    </div>
                    <div style={{
                        height: '0.3in',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        borderBottom: '1px dotted #000000',
                    }}>
                        <span style={trackIdStyle}>{trackId}</span>
                    </div>

                    <div style={{
                        // height: '0.3in',
                        display: 'flex',
                        padding: 5,
                        alignItems: 'center',
                        justifyContent: 'center',
                        borderBottom: '1px dotted #000000',
                    }}>
                        {
                            note ?
                                <span style={noteStyle}>Note : {note}</span>
                                :
                                <span style={noteStyle}>No note here</span>
                        }

                    </div>

                    <div style={{
                        width: childContainerW,
                        display: 'flex',
                        justifyContent: 'center',
                        marginTop: '0.01in',
                        marginBottom: '0.01in',
                        borderBottom: '1px dotted #000000',

                    }}>
                        <div style={dateStyle}>Create Date : {createDate}</div>
                    </div>
                    <div style={{
                        width: childContainerW,
                        height: '0.3in',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}>
                        <div style={{ ...dateStyle, fontWeight: '600' }}>"{storeName}"</div>
                    </div>
                    <div style={{
                        width: childContainerW,
                        height: '0.3in',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}>
                        <div style={{ ...dateStyle, fontWeight: '500', fontSize: 13 }}>Ninja Van Call Center - 09 777 111 001</div>
                    </div>


                    <div style={{
                        width: childContainerW,
                        height: '3in',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}>
                        -
                    </div>
                </div>


            </div>
        )
    }
}

export default AirWayBillSlip
