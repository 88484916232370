import { AlignRightOutlined, MenuOutlined } from '@ant-design/icons';
import { Col, ConfigProvider, Drawer, Image, Layout, Menu, Modal, Popover, Row } from 'antd';
import Sider from 'antd/es/layout/Sider';
import { Content } from 'antd/es/layout/layout';
import MenuItem from 'antd/es/menu/MenuItem';
import i18next from 'i18next';
import cookies from 'js-cookie';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom';
import './App.css';
import { NJVButton } from './component/core-component';
import { SvgMyanmarFlag, SvgUKFlag } from './component/custom-svgIcon';
import { ADMIN, ADMIN_SIDE_MENU, SIDE_MENU, STAFF } from './core/constant';
import { LOC_getUserType } from './core/local-storage';
import { GenerateSvgIconBaseOnName } from './core/nvm-utils';
import { signOut } from './redux/auth-slice';
import { clearCookie, currentSelectedMenu, updateCurrentRoute, updateSelectedMenu } from './redux/cookie-slice';
import { clearProfile, fullName } from './redux/profile-slice';
import CustomPath from './routes/custom-path';


import { theme } from 'antd';
const { Header } = Layout;
const logo = require('./asset/partner-logo.png');

const App = () => {

  const {
    token: { colorBgContainer },
  } = theme.useToken();
  const shipper_profile = require('./asset/shipper_profile.png');

  const dispatch = useDispatch();
  const location = useLocation();
  const userName = useSelector(fullName)
  const navigate = useNavigate();
  const currentSelectedMenuIndex = useSelector(currentSelectedMenu);

  const [open, setOpen] = useState(false);
  const [currentLanguage, setCurrentLanguage] = useState(false);
  const [selected, setSelected] = useState(0)
  const [clickMenu, setClickMenu] = useState(false)
  const [logoutModalOpen, setLogoutModalOpen] = useState(false);

  useEffect(() => {
    dispatch(updateCurrentRoute(location.pathname));
    let currentLanCode = cookies.get("i18next") || "mm"
    setCurrentLanguage(currentLanCode)
    if (LOC_getUserType() === "SP_ADMIN") {
      i18next.changeLanguage("en")
    }
    setSelected(currentSelectedMenuIndex)
  }, [location.pathname, currentLanguage, currentSelectedMenuIndex])

  const showDrawer = () => {
    setOpen(true);
  }

  const changeLanguage = () => {
    if (currentLanguage === 'en') {
      i18next.changeLanguage("mm")
      setCurrentLanguage('mm')
    } else if (currentLanguage === 'mm') {
      i18next.changeLanguage("en")
      setCurrentLanguage('en')
    }
  }

  const onClose = () => {
    setOpen(false);
  }

  const confirmLogout = () => {
    setLogoutModalOpen(false);
    dispatch(signOut())
    dispatch(clearCookie())
    dispatch(clearProfile())
    navigate(CustomPath.login)
  }

  const handleSelectMenu = (event) => {
    setSelected(event.key)
    setClickMenu(true);
    dispatch(updateSelectedMenu(event.key))
  }

  return (
    <>
      <Modal title={i18next.t("logout_confirmation")} centered open={logoutModalOpen} onOk={() => confirmLogout()}
        onCancel={() => setLogoutModalOpen(false)} onText={i18next.t("logout")} cancelText={i18next.t("cancel")} >
        <p>{i18next.t("are_you_sure_to_logout")}</p>
      </Modal>
      <ConfigProvider theme={{
        components: {
          Layout: {
            bodyBg: "#fff",
          },
          Menu: {
            itemBorderRadius: 0,
            itemSelectedBg: '#c2002f',
          }
        }
      }}>
        <Layout>
          <Header
            style={{
              height: "70px",
              position: 'sticky',
              top: 0,
              zIndex: 1,
              width: '100%',
              paddingLeft: 10,
              paddingRight: 10,
              boxShadow: '2px 2px 10px rgba(0, 0, 0, 0.1)',
              background: colorBgContainer,
            }}
          >
            <Row style={{ height: '100%' }} >
              <Col lg={12} md={0} sm={0} xs={0}>
                <Link to={CustomPath.dashboard}>
                  <Image width={200} src={logo} preview={false} style={{ margin: '5px 0' }} />
                </Link>
              </Col>
              <Col lg={12} md={0} sm={0} xs={0}>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'right' }}>
                  <div style={{ textAlign: 'right', marginRight: 10 }}>
                    <div style={{ fontSize: 18, fontWeight: '600' }}>
                      {userName}
                    </div>
                  </div>
                  {
                    LOC_getUserType() === "SP_ADMIN" ? <></> :
                      currentLanguage === 'en' ?
                        <SvgUKFlag width={35} height={35} style={{ cursor: 'pointer', marginRight: 7 }}
                          onClick={changeLanguage} />
                        :
                        <SvgMyanmarFlag width={35} height={35} style={{ cursor: 'pointer', marginRight: 7 }}
                          onClick={changeLanguage} />
                  }
                  <Popover
                    placement="bottomRight"
                    overlayStyle={{ width: 300 }}
                    content={
                      <NJVButton type='primary' size='large' style={{ width: '100%' }} onClick={() => setLogoutModalOpen(true)} text="Logout" />
                    } >
                    <Image preview={false}
                      style={{
                        width: 45,
                        height: 45,
                        padding: 1,
                        objectFit: 'cover',
                        cursor: 'pointer'
                      }}
                      src={shipper_profile}
                    />
                  </Popover>
                </div>
              </Col>
              <Col lg={0} md={24} sm={24} xs={24}>
                <Row>
                  <Col span={12}>
                    <NJVButton type='text' shape='square' icon={<AlignRightOutlined />} size={'large'} onClick={showDrawer} />
                    <Link to={CustomPath.dashboard}>
                      <Image width={120} src={logo} preview={false} />
                    </Link>
                  </Col>
                  <Col span={12} style={{ textAlign: 'right' }}>
                    <Popover
                      placement="bottomRight"
                      overlayStyle={{ width: 300 }}
                      content={
                        <NJVButton type='primary' size='large' style={{ width: '100%' }} onClick={() => setLogoutModalOpen(true)} text="Logout" />
                      } >
                      <Image preview={false}
                        style={{
                          width: 45,
                          height: 45,
                          padding: 1,
                          objectFit: 'cover',
                          cursor: 'pointer'
                        }}
                        src={shipper_profile}
                      />
                    </Popover>
                  </Col>
                </Row>
              </Col>
              <Drawer
                closable={false}
                placement="left"
                onClose={onClose}
                open={open}
                width={240}
              >
                <ConfigProvider
                  theme={{
                    token: {
                      controlHeightLG: 55,
                      margin: 0
                    },
                    components: {
                      Menu: {
                        itemMarginInline: 0,
                        itemMarginBlock: 0
                      }
                    }
                  }}
                >
                  <Menu
                    style={{
                      background: '#f5f5f5',
                      border: 'none',
                      display: 'inline'
                    }}
                    mode='inline'
                    onSelect={handleSelectMenu}
                    selectedKeys={[currentSelectedMenuIndex]}
                  >
                    {
                      ADMIN === LOC_getUserType() && ADMIN_SIDE_MENU.map((menu, index) => {
                        const currentIndex = '' + (index + 1)

                        return <MenuItem key={currentIndex}>
                          <Link to={menu.route} onClick={onClose}>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                              {GenerateSvgIconBaseOnName(menu.name, selected, currentIndex)}
                              <span className="sidebar-text">{i18next.t(menu.name)}</span>
                            </div>
                          </Link>
                        </MenuItem>
                      })
                    }
                    {
                      STAFF === LOC_getUserType() && SIDE_MENU.map((menu, index) => {
                        const currentIndex = '' + (index + 1)
                        return <MenuItem key={currentIndex}>
                          <Link to={menu.route} onClick={onClose}>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                              {GenerateSvgIconBaseOnName(menu.name, selected, currentIndex)}
                              <span className="sidebar-text">{i18next.t(menu.name)}</span>
                            </div>
                          </Link>
                        </MenuItem>
                      })
                    }
                  </Menu>
                </ConfigProvider>
              </Drawer>
            </Row>
          </Header>
          <Layout
          >
            <Sider width={240}
              className='custom-side-bar'
              style={{
                height: "90vh",
                position: 'sticky',
                top: '70px',
                zIndex: 1,
                width: '100%',
                backgroundColor: '#f5f5f5'
              }}
            >
              <ConfigProvider
                theme={{
                  token: {
                    controlHeightLG: 55,
                    margin: 0
                  },
                  components: {
                    Menu: {
                      itemMarginInline: 0,
                      itemMarginBlock: 0
                    }
                  }
                }}
              >
                <Menu
                  style={{
                    background: '#f5f5f5',
                    border: 'none',
                  }}
                  onSelect={handleSelectMenu}
                  selectedKeys={[currentSelectedMenuIndex]}
                  mode='inline'
                >
                  {
                    ADMIN === LOC_getUserType() && ADMIN_SIDE_MENU.map((menu, index) => {
                      const currentIndex = '' + (index + 1)

                      return <MenuItem key={currentIndex} icon={GenerateSvgIconBaseOnName(menu.name, selected, currentIndex)}>
                        <Link to={menu.route} onClick={onClose}>
                          <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                            <span className="sidebar-text">{i18next.t(menu.name)}</span>
                          </div>
                        </Link>
                      </MenuItem>
                    })
                  }
                  {
                    STAFF === LOC_getUserType() && SIDE_MENU.map((menu, index) => {
                      const currentIndex = '' + (index + 1)

                      return <MenuItem key={currentIndex} icon={GenerateSvgIconBaseOnName(menu.name, selected, currentIndex)}>
                        <Link to={menu.route} onClick={onClose}>
                          <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                            <span className="sidebar-text">{i18next.t(menu.name)}</span>
                          </div>
                        </Link>
                      </MenuItem>
                    })
                  }
                </Menu>
                <div style={{ width: '90%', display: 'flex', alignItems: 'center', flexDirection: 'column', marginBottom: '20px', position: 'absolute', bottom: '0', left: 10 }}>
                  <h1>09-777 111001</h1>
                </div>
              </ConfigProvider>
            </Sider>
            <Content
              style={{
                margin: '24px 16px',
              }}
            >
              <Outlet />
            </Content>
          </Layout>
        </Layout>
      </ConfigProvider>
    </>
  );
};
export default App;
