import { message } from "antd";
import axios from "axios";
import { useNavigate } from 'react-router-dom';
import { LOC_getRefreshToken } from "../core/local-storage";
import Api from "./api";
import CustomPath from "../routes/custom-path";
const axiosInstance = axios.create({
    baseURL: Api.host
})


export const Authenticate = async (userType, phoneNumber, password) => {
    try {
        const response = await axiosInstance.post(Api.login, {
            "username": phoneNumber,
            "password": password
        }, { headers: { 'accountType': userType } })
        return response
    } catch (error) {
        return error
    }
}
export const RefreshToken = async (props) => {
    try {
        
        const refreshToken = LOC_getRefreshToken()
        const response = await axiosInstance.post(Api.refresh_token, {
            "refreshToken": refreshToken
        })
        if (response && response.status === 200) {
            return response.data
        }
    } catch (error) {
        message.destroy()
        message.error("Please Login Again!")
    }
}