import { compose } from "@reduxjs/toolkit";
import { Button, Col, List, Row, Skeleton } from "antd";
import dayjs from 'dayjs';
import i18next from "i18next";
import React from "react";
// import { SvgNotiActivity, SvgNotiAnnocement, SvgNotiSystem, SvgOrderDollyOutlined } from "../../component/custom-svgIcon";
import Lottie from "lottie-react";
import NoDataFoundAnimation from '../../animate-lottie/lottie-not-data-found.json';
import { NJVCard, TitleLevel3 } from "../../component/core-component";
import Theme from "../../component/theme";
import { Constant } from "../../core/constant";
import Api from "../../network/api";
import { ApiInstance } from "../../network/api-manager";
import { HTTP_METHOD } from "../../network/httpMethod";
import { MEDIA_TYPE } from "../../network/mediaType";
import withRouter from "../../network/with-router";
import CustomPath from "../../routes/custom-path";



const empty_history_image = require('../../asset/shipper_history_empty.png')
class NotificationPage extends React.Component {


    constructor(props) {
        super(props)
        this.state = {
            isDataFetching: true,
            data: [],
            page: 0,
            size: Constant.pageSize,
            isLastData: false,
            show_load_more_button: false
        }
    }

    componentDidMount() {
        this.fetchData()
    }

    fetchData = async () => {
        try {
            const param = {
                page: 0,
                size: 20,
                notificationType: 'ANNOUNCEMENT'
            }
            const response = await ApiInstance(Api.notification_history_filter, HTTP_METHOD.GET, MEDIA_TYPE.JSON,param)

            if (response) {
                let updatedList = []
                response.content && response.content.map(noti => {

                    const notiTime = dayjs(noti.createdDate, 'YYYY-MM-DD HH:mm:ss');
                    if (notiTime) {
                        noti = { ...noti, createdDate: notiTime.format('DD MMM,YYYY hh:mm A') }
                    }
                    updatedList.push(noti)
                })

                this.setState({
                    data: updatedList,
                    isLastData: updatedList.length < 20
                })
            }
        } catch (error) {

        }
        this.setState({ isDataFetching: false })

    }


    loadMore = async () => {
        const { data, pageSize, page } = this.state
        this.setState({
            isLoadMore: true
        })
        const param = {
            size: pageSize,
            page: page + 1,
            notificationType: 'ANNOUNCEMENT'
        }

        try {
            const response = await ApiInstance(Api.notification_history_filter, HTTP_METHOD.GET, MEDIA_TYPE.JSON, param)
            if (response) {
                let updatedList = data
                let isLastData = false
                if (response.content) {
                    response.content.map(noti => {

                        const notiTime = dayjs(noti.createdDate, 'YYYY-MM-DD HH:mm:ss');
                        if (notiTime) {
                            noti = { ...noti, createdDate: notiTime.format('DD MMM, YYYY hh:mm A') }
                        }
                        updatedList.push(noti)
                    })
                    if (response.content.length < 20) {
                        isLastData = true
                    }
                }
                this.setState({
                    data: updatedList,
                    isLastData: isLastData,
                    page: page + 1
                })
            }
        } catch (error) {

        }
        this.setState({
            isLoadMore: false
        })
    }

    routToNotificationDetail = (id, trackingNumber, notificationType) => {
        if (notificationType === 'ORDER_ACTIVITY') {
            const data = {
                id: trackingNumber,
                isFromOrderHistory: false
            }
            this.props.navigate(
                CustomPath.order_detail,
                { state: { data: data } }
            );
        } else {
            this.props.navigate(
                CustomPath.notification_detail,
                { state: { data: id } }
            );
        }

    }

    render() {
        const { data, isDataFetching, isLoadMore, isLastData } = this.state

        return (
            <>
                <Row>
                    <Col span={1} />
                    <Col span={22}>
                        <Row>
                            <Col span={24}>
                                <TitleLevel3 label={i18next.t("notification")} />
                            </Col>
                        </Row>
                        <Row>
                            {
                                isDataFetching ?
                                    <Skeleton active />
                                    :
                                    <Col span={24}>
                                        {
                                            data ?
                                                <>
                                                    <List
                                                        itemLayout="horizontal"
                                                        dataSource={data}
                                                        renderItem={(item, index) => (
                                                            <NJVCard
                                                                style={{
                                                                    paddingBottom: 10,
                                                                    paddingTop: 10,
                                                                    marginBottom: 2,
                                                                    borderRadius: 20,
                                                                    width: '100%',
                                                                    cursor: 'pointer'
                                                                }}
                                                                bordered={false}
                                                                onClick={() => this.routToNotificationDetail(item.id, item.trackingNumber, item.notificationType)}
                                                            >
                                                                <Row style={{ width: '100%' }}>
                                                                    <Col span={24}>
                                                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                            <div style={{ borderRadius: '10px',border: '1px solid #fff',backgroundColor: '#fff',padding: '5px 10px',display: 'flex',flexDirection: 'column',alignItems: 'center',
                                                                                    marginRight: 20}}>
                                                                                <span style={{ color: Theme.colors.primary,fontWeight: 'bold' }}>{item.createdDate.split(/,|\s/)[1]}</span>
                                                                                <span style={{ fontWeight: 'bold' }}>{item.createdDate.split(' ')[0]}</span>
                                                                            </div>
                                                                            {/* <div
                                                                                style={{
                                                                                    width: 45,
                                                                                    height: 42,
                                                                                    borderRadius: 30,
                                                                                    display: 'flex',
                                                                                    justifyContent: 'center',
                                                                                    alignItems: 'center',
                                                                                    backgroundColor: Colors.primary,
                                                                                    marginLeft: 10,
                                                                                    marginRight: 20
                                                                                }}>
                                                                                {
                                                                                    item.notificationType === 'ACTIVITY' ?
                                                                                        <SvgNotiActivity width={20} height={20} color={'#fff'} />
                                                                                        :
                                                                                        <>
                                                                                            {
                                                                                                item.notificationType === 'SYSTEM' ?
                                                                                                    <SvgNotiSystem width={20} height={20} color={'#fff'} />
                                                                                                    :
                                                                                                    <>
                                                                                                        {
                                                                                                            item.notificationType === 'ANNOUNCEMENT' ?
                                                                                                                <SvgNotiAnnocement width={20} height={20} color={'#fff'} />
                                                                                                                :
                                                                                                                <>
                                                                                                                    {
                                                                                                                        item.notificationType === 'ORDER_ACTIVITY' ?
                                                                                                                            <SvgOrderDollyOutlined width={20} height={20} color={'#fff'} />
                                                                                                                            :
                                                                                                                            <></>
                                                                                                                    }
                                                                                                                </>
                                                                                                        }
                                                                                                    </>
                                                                                            }
                                                                                        </>
                                                                                }

                                                                            </div> */}
                                                                            <div style={{ width: '100%' }}>
                                                                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                                                    <span
                                                                                        style={{
                                                                                            fontSize: 17,
                                                                                            fontWeight: '600'
                                                                                        }}
                                                                                    > {item.title}</span>
                                                                                    <div>
                                                                                        <span>
                                                                                            {item.createdDate.split(/,|\s/)[3]} &nbsp;
                                                                                            {item.createdDate.split(/,|\s/)[4]}
                                                                                        </span>
                                                                                    </div>
                                                                                </div>
                                                                                <div>{item.content}</div>
                                                                            </div>
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                            </NJVCard>

                                                        )}
                                                    />
                                                    {
                                                        isLastData ?
                                                            <></>
                                                            :
                                                            <div>
                                                                <Button type="primary" onClick={() => this.loadMore()} loading={isLoadMore}>
                                                                    Load More
                                                                </Button>
                                                            </div>

                                                    }

                                                </>
                                                :
                                                <>
                                                    <Col span={24} style={{ display: 'flex', justifyContent: 'center' }}>
                                                    <Lottie
                                                        style={{ height: '200px' }}
                                                        animationData={NoDataFoundAnimation}
                                                        loop={true}
                                                        autoplay={true}
                                                    />
                                                    </Col>
                                                    <Col span={24} style={{ textAlign: 'center', marginTop: 20 }}>
                                                        <div style={{ fontSize: 16, fontWeight: '600', marginBottom: 10 }}>
                                                            {i18next.t("no_notification_yet")}
                                                        </div>
                                                        <div style={{ fontSize: 16 }}>
                                                            {i18next.t("msg_notification")}
                                                        </div>
                                                    </Col>
                                                </>

                                        }
                                    </Col>

                            }
                        </Row>

                    </Col >
                </Row >

            </>
        )
    }
}

export default compose(withRouter)(NotificationPage)