import React, { Component } from "react";
import { Route, Routes } from 'react-router-dom';

import { compose } from "@reduxjs/toolkit";
import App from "../App";
import withRouter from "../network/with-router";
import RedirectLogin from "../page/error-page/redirect-login";
import LoginPage from "../page/login/login-page";
import CustomPath from "./custom-path";

import RouteGuard from "../component/route-guard";
import { LOC_getUserType } from "../core/local-storage";
import AdminDashboardPage from "../page/admin-dashboard";
import NoServiceItem from "../page/none-service/none-service-item";
import NotificationDetail from "../page/notification/notification-detail";
import NotificationPage from "../page/notification/notification-history";
import AdvanceOrderForm from '../page/partner-order/advance-order-form';
import OrderConfirm from "../page/partner-order/order-confim";
import OrderDetail from "../page/partner-order/order-detail";
import OrderHistory from "../page/partner-order/order-history";
import PrintAirwayBillSlip from "../page/partner-order/print-awb-slip";
import StoreAdminOrderHistory from "../page/partner-order/store-admin-order-history";
import PartnershipStoreForm from "../page/partner-store/partnership-store-form";
import PartnershipStorePage from "../page/partner-store/partnership-store-page";
import PartnershipStoreUpdate from "../page/partner-store/partnership-store-update";
import Report from "../page/partner-store/report";
import NotFound from "../page/error-page/not-found";
import StoreDashboardPage from "../page/store-dashboard";

const ADMIN = 'SP_ADMIN'
const STAFF = 'SP_STAFF'

class Router extends Component {
    render() {
        return (
            <Routes>
                {
                    this.props.authenticated ?
                        (
                            <>
                                {
                                    LOC_getUserType() === ADMIN ?
                                        (
                                            <Route element={<App />}>
                                                <Route path={CustomPath.dashboard} element={<AdminDashboardPage />} />
                                                <Route path={CustomPath.order_create} element={<AdvanceOrderForm />} />
                                                <Route path={CustomPath.partnership_store} element={<PartnershipStorePage />} />
                                                <Route path={CustomPath.partnership_store_create} element={<PartnershipStoreForm />} />
                                                <Route path={CustomPath.partnership_store_update} element={<PartnershipStoreUpdate />} />
                                                <Route path={CustomPath.partnership_store_admin_order_history} element={<StoreAdminOrderHistory />} />
                                                <Route path={CustomPath.order_detail} element={<OrderDetail />} />
                                                <Route path={CustomPath.report} element={<Report />} />
                                                <Route path="/*" element={<NotFound />} />
                                            </Route>
                                        )
                                        :
                                        (
                                            <>
                                                {
                                                    LOC_getUserType() === STAFF ?
                                                        (
                                                            <Route element={<App />}>
                                                                <Route path={CustomPath.dashboard} element={<StoreDashboardPage />} />
                                                                <Route path={CustomPath.order_create} element={<AdvanceOrderForm />} />
                                                                <Route path={CustomPath.order_history} element={<OrderHistory />} />
                                                                <Route path={CustomPath.order_detail} element={<OrderDetail />} />
                                                                <Route path={CustomPath.order_confirmation} element={<OrderConfirm />} />
                                                                <Route path={CustomPath.notification_history} element={<NotificationPage />} />
                                                                <Route path={CustomPath.notification_detail} element={<NotificationDetail />} />
                                                                <Route path={CustomPath.airwaybill} element={<PrintAirwayBillSlip />} />
                                                                <Route path={CustomPath.none_service} element={<NoServiceItem />} />
                                                                <Route path="/*" element={<NotFound />} />
                                                            </Route>
                                                        )
                                                        :
                                                        <></>
                                                }
                                            </>
                                        )
                                }
                                <Route path={CustomPath.login} element={<RouteGuard><LoginPage /></RouteGuard>} />
                            </>
                        )
                        :
                        <Route path="/*" element={<RedirectLogin />} />
                }
                <Route path={CustomPath.login} element={<LoginPage />} />
            </Routes >
        )
    }
}
export default compose(withRouter)(Router)
