import { EyeOutlined } from "@ant-design/icons";
import { compose } from "@reduxjs/toolkit";
import { Col, Row, Select, Skeleton, Table, message } from "antd";
import dayjs from 'dayjs';
import React from "react";
import { FormLabelOptional, NJVButton, NJVCard, NJVDatePicker, NJVInput, NJVSelect, TitleLevel3 } from "../../component/core-component";
import { Constant } from "../../core/constant";

import i18next from "i18next";
import Api from "../../network/api";
import { ApiInstance, downloadExcel } from "../../network/api-manager";
import { HTTP_METHOD } from "../../network/httpMethod";
import { MEDIA_TYPE } from "../../network/mediaType";
import withRouter from "../../network/with-router";
import CustomPath from "../../routes/custom-path";
const { Option } = Select

class StoreAdminOrderHistory extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            backPage: props.location.state?.page || 1,
            backFilterData: props.location.state?.filterData || {},
            data: [],
            order_history_collection: [],
            historyMap: {},
            titleList: [],
            isDataFetching: false,
            isSearchingData: false,
            isDownloadExcel: false,
            totalPage: 0,
            pageSize: Constant.pageSize,
            page: 1,
            // filterData: props.location.state?.filterData ? {...props.location.state?.filterData} : {},
            filterData: {},
            orderStatusList: [],
            storeNameList: [],
        }
    }

    componentDidMount() {

        this.fetchOrderStatus()
        let filterData = {}
        filterData = { ...this.props.location.state?.filterData } || {}
        if (this.props.location.state?.filterData?.fromDate) {
            filterData = { ...filterData, fromDate: dayjs(this.props.location.state?.filterData?.fromDate) }
        }
        if (this.props.location.state?.filterData?.toDate) {
            filterData = { ...filterData, toDate: dayjs(this.props.location.state?.filterData?.toDate) }
        }
        this.setState({
            filterData
        }, () => this.fetchData(this.state.backPage ? parseInt(this.state.backPage) : 1))
        this.fetchStoreName()

    }

    fetchData = async (pageNumber) => {
        let { pageSize, page, filterData } = this.state
        this.setState({
            isDataFetching: true
        })
        try {
            let params = {
                page: pageNumber ? pageNumber - 1 : page - 1,
                size: pageSize
            }
            if (filterData) {
                if (filterData.trackingNumber || filterData.recipientName || filterData.recipientPhoneNumber || filterData.senderName || filterData.senderPhoneNumber) {
                    filterData = {
                        ...filterData,
                        trackingNumber: filterData.trackingNumber && filterData.trackingNumber?.trim(),
                        recipientName: filterData.recipientName && filterData.recipientName?.trim(),
                        recipientPhoneNumber: filterData.recipientPhoneNumber && filterData.recipientPhoneNumber?.trim(),
                        senderName: filterData.senderName && filterData.senderName?.trim(),
                        senderPhoneNumber: filterData.senderPhoneNumber && filterData.senderPhoneNumber?.trim(),
                    }
                }
                if ((filterData.fromDate && !filterData.toDate) || (!filterData.fromDate && filterData.toDate)) {
                    message.error("Invalid date")
                    return
                }
                if (filterData.fromDate > filterData.toDate) {
                    message.error("Invalid date")
                    return
                }

                if (filterData.fromDate) {
                    filterData = { ...filterData, 'fromDate': filterData.fromDate.format("YYYY-MM-DD") }
                }
                if (filterData.toDate) {
                    filterData = { ...filterData, 'toDate': filterData.toDate.format("YYYY-MM-DD") }
                }
                if (filterData.senderPhoneNumber) {
                    const updatedValue = filterData.senderPhoneNumber.charAt(0) === '0' ? filterData.senderPhoneNumber.slice(1) : filterData.senderPhoneNumber;
                    filterData = { ...filterData, senderPhoneNumber: updatedValue }
                }
                if (filterData.recipientPhoneNumber) {
                    const updatedValue = filterData.recipientPhoneNumber.charAt(0) === '0' ? filterData.recipientPhoneNumber.slice(1) : filterData.recipientPhoneNumber;
                    filterData = { ...filterData, recipientPhoneNumber: updatedValue }
                }

                params = {
                    ...params,
                    ...filterData
                }
            }
            const response = await ApiInstance(Api.partnership_store_admin_order_history, HTTP_METHOD.GET, MEDIA_TYPE.JSON, params)
            if (response && response.content) {
                console.log(response.content)
                let updatedList = []

                response.content.map((orderHistory) => {
                    orderHistory = { ...orderHistory, key: orderHistory.id }
                    if (orderHistory.orderModifiedDate) {
                        const orderModifiedDate = dayjs(orderHistory.orderModifiedDate, 'YYYY-MM-DD HH:mm:ss');
                        orderHistory = { ...orderHistory, orderModifiedDate: orderModifiedDate.format('DD MMM, YYYY hh:mm A') }
                    }
                    updatedList.push(orderHistory)
                })
                this.setState({
                    data: updatedList,
                    totalPage: response.totalElements,
                    page: pageNumber ? pageNumber : page,
                })
            }
        } catch (error) {
        } finally {
            this.setState({
                isDataFetching: false,
                isSearchingData: false
            })
        }
    }

    fetchOrderStatus = async () => {
        try {
            var params = {
                'enumType': 'ORDER_STATUS'
            }
            const response = await ApiInstance(Api.enum_filter, HTTP_METHOD.GET, MEDIA_TYPE.JSON, params)
            if (response) {
                let temp = []
                response.map((res, index) => {
                    temp.push({
                        key: index + 1,
                        value: res.key,
                        label: res.value,
                    });
                })
                this.setState({
                    orderStatusList: temp
                })
            }
        } catch (error) {

        }
    }

    fetchStoreName = async () => {
        try {
            const response = await ApiInstance(Api.partnership_storename, HTTP_METHOD.GET, MEDIA_TYPE.JSON)
            if (response) {
                let storeNameList = []
                response.map((res) => {
                    storeNameList.push({
                        value: res.storeId,
                        label: res.storeName,
                    });
                })
                this.setState({
                    storeNameList
                })
            }
        } catch (error) {
        }
    }

    search = () => {
        this.setState({
            isSearchingData: true
        }, () => this.fetchData(null))
    }

    updateFilterData = (key, value) => {
        let { filterData } = this.state
        filterData = {
            ...filterData,
            [key]: value
        }
        this.setState({
            filterData: filterData
        })
    }

    pagination = (page, size) => {
        this.fetchData(page)
        this.setState({
            page: page - 1
        })
    }

    routToNotificationDetail = (trackingNumber) => {
        let { page, filterData } = this.state

        if (filterData.fromDate) {
            filterData = { ...filterData, fromDate: filterData.fromDate.format("YYYY-MM-DD") }
        }
        if (filterData.toDate) {
            filterData = { ...filterData, toDate: filterData.toDate.format("YYYY-MM-DD") }
        }
        const data = {
            id: trackingNumber,
            isFromOrderHistory: true,
            route: CustomPath.partnership_store_admin_order_history,
            page,
            filterData
        }

        this.props.navigate(
            CustomPath.order_detail,
            { state: { data: data } }
        );
    }
    downloadShipperOrderExcel = async () => {
        let { filterData, page, pageSize } = this.state
        try {
            let params = {
                page: page - 1,
                size: pageSize
            }
            if (filterData) {
                if ((filterData.fromDate && !filterData.toDate) || (!filterData.fromDate && filterData.toDate)) {
                    message.error("Invalid date")
                    return
                }
                if (filterData.fromDate > filterData.toDate) {
                    message.error("Invalid date")
                    return
                }
                if (filterData.fromDate) {
                    filterData = { ...filterData, 'fromDate': filterData.fromDate.format("YYYY-MM-DD") }
                }
                if (filterData.toDate) {
                    filterData = { ...filterData, 'toDate': filterData.toDate.format("YYYY-MM-DD") }
                }
                if (filterData.senderPhoneNumber) {
                    const updatedValue = filterData.senderPhoneNumber.charAt(0) === '0' ? filterData.senderPhoneNumber.slice(1) : filterData.senderPhoneNumber;
                    filterData = { ...filterData, senderPhoneNumber: updatedValue }
                }
                if (filterData.recipientPhoneNumber) {
                    const updatedValue = filterData.recipientPhoneNumber.charAt(0) === '0' ? filterData.recipientPhoneNumber.slice(1) : filterData.recipientPhoneNumber;
                    filterData = { ...filterData, recipientPhoneNumber: updatedValue }
                }
                params = {
                    ...params,
                    ...filterData
                }
            }
            await downloadExcel(Api.order_download_excel, params, "Order List")
        } catch (error) {
        }
    }

    render() {
        const { isDataFetching, pageSize, totalPage, page, data, orderStatusList, storeNameList, filterData, isDownloadExcel, isSearchingData } = this.state

        const getRowClassName = (record, index) => {
            return index % 2 === 0 ? 'blue-row' : 'black-row';
        };
        const columns = [
            {
                title: 'No.',
                key: 'index',
                render: (value, item, index) => <span>{(page - 1) * pageSize + index + 1}</span>,
                width: 5
            },
            {
                title: 'Tracking Number',
                dataIndex: 'trackingNumber',
                key: 'trackingNumber',
            },
            {
                title: 'Pickup  Date',
                dataIndex: 'pickupDate',
                key: 'pickupDate',
            },
            {
                title: 'Income',
                key: 'income',
                render: (orderHistory) => (
                    <>
                        <div>{orderHistory.deliveryCharges * 0.1}</div>
                    </>
                ),
                align: 'center'
            },
            {
                title: 'Delivery Fees',
                key: 'deliveryCharges',
                render: (orderHistory) => (
                    <div>{orderHistory.deliveryCharges ? orderHistory.deliveryCharges : 0}</div>
                ),
                align: 'center'
            },
            {
                title: 'Status',
                dataIndex: 'ninjaCoreOrderStatus',
                key: 'ninjaCoreOrderStatus',
                width: 20
            },
            {
                title: 'Store Info',
                key: 'modifiedUserInfo',
                render: (orderHistory) => (
                    <>
                        <div>{orderHistory?.shipper?.fullName}</div>
                        <div>{orderHistory?.shipper?.township?.name}</div>
                    </>
                )
            },
            {
                title: 'Action',
                key: 'action',
                dataIndex: '',
                textAlign: 'center',
                width: 10,
                render: (orderHistory) => (
                    <>
                        <NJVButton onClick={() => this.routToNotificationDetail(orderHistory.trackingNumber)} type="primary" shape="circle" style={{ marginBottom: 5 }} text={<EyeOutlined />} />
                    </>
                )
            }
        ]

        return (
            <Row>
                <Col span={1} />
                <Col span={22}>
                    <Row>
                        <Col span={24}>
                            <TitleLevel3 label={i18next.t("order_history")} />
                            <br />
                        </Col>
                    </Row>
                    <Row style={{ marginBottom: 10 }}>
                        <Col span={24}>
                            <NJVCard
                                style={{ paddingTop: 15, paddingBottom: 15 }}
                                bordered={false}
                            >
                                <Row gutter={[16, 16]}>
                                    <Col span={6}>
                                        <FormLabelOptional label={i18next.t("tracking_number")} />
                                        <NJVInput
                                            value={filterData.trackingNumber}
                                            size="large" placeholder={i18next.t("tracking_number")} onChange={(event) => this.updateFilterData('trackingNumber', event.target.value)} />
                                    </Col>
                                    <Col span={6}>
                                        <FormLabelOptional label={i18next.t("from_date")} />
                                        <NJVDatePicker
                                            value={filterData.fromDate}
                                            size="large"
                                            onChange={(value) => this.updateFilterData('fromDate', value)}
                                            style={{ width: '100%' }}
                                            placeholder={i18next.t("from_date")}
                                            format="YYYY-MM-DD"
                                        />
                                    </Col>
                                    <Col span={6}>
                                        <FormLabelOptional label={i18next.t("to_date")} />
                                        <NJVDatePicker
                                            value={filterData.toDate}
                                            size="large"
                                            onChange={(value) => this.updateFilterData('toDate', value)}
                                            style={{ width: '100%' }}
                                            placeholder={i18next.t("to_date")}
                                            format="YYYY-MM-DD"
                                        />
                                    </Col>
                                    <Col span={6}>
                                        <FormLabelOptional label={i18next.t("order_status")} />
                                        <NJVSelect
                                            value={filterData.orderStatus}
                                            onChange={(value) => this.updateFilterData('orderStatus', value)}
                                            size="large"
                                            allowClear={true}
                                            style={{ width: '100%' }}
                                            placeholder={i18next.t("order_status")} >
                                            {
                                                orderStatusList.map((status, index) => {
                                                    return <Option key={index} value={status.value}>{status.label}</Option>
                                                })
                                            }
                                        </NJVSelect>
                                    </Col>
                                    <Col span={6}>
                                        <FormLabelOptional label={i18next.t("receiver_name")} />
                                        <NJVInput value={filterData.recipientName} size="large" placeholder={i18next.t("receiver_name")} onChange={(event) => this.updateFilterData('recipientName', event.target.value)} />
                                    </Col>
                                    <Col span={6}>
                                        <FormLabelOptional label={i18next.t("receiver_phone_number")} />
                                        <NJVInput value={filterData.recipientPhoneNumber} size="large" placeholder={i18next.t("receiver_phone_number")} onChange={(event) => this.updateFilterData('recipientPhoneNumber', event.target.value)} />
                                    </Col>
                                    <Col span={6}>
                                        <FormLabelOptional label={i18next.t("sender_name")} />
                                        <NJVInput value={filterData.senderName} size="large" placeholder={i18next.t("sender_name")} onChange={(event) => this.updateFilterData('senderName', event.target.value)} />
                                    </Col>
                                    <Col span={6}>
                                        <FormLabelOptional label={i18next.t("sender_phone_number")} />
                                        <NJVInput value={filterData.senderPhoneNumber} size="large" placeholder={i18next.t("sender_phone_number")} onChange={(event) => this.updateFilterData('senderPhoneNumber', event.target.value)} />
                                    </Col>
                                    <Col span={6}>
                                        <FormLabelOptional label={i18next.t("store_name")} />
                                        <NJVSelect
                                            value={filterData.storeId}
                                            onChange={(value) => this.updateFilterData('storeId', value)}
                                            size="large"
                                            allowClear={true}
                                            style={{ width: '100%' }}
                                            placeholder={i18next.t("select_store_name")} >
                                            {
                                                storeNameList.map((status, index) => {
                                                    return <Option key={index} value={status.value}>{status.label}</Option>
                                                })
                                            }
                                        </NJVSelect>
                                    </Col>
                                    <Col span={6}>
                                        <FormLabelOptional label="&nbsp;" />
                                        <NJVButton
                                            loading={isSearchingData}
                                            size="large"
                                            style={{ width: '100%' }}
                                            type="primary" onClick={() => this.search()} text="Search" />
                                    </Col>
                                    <Col span={6}>
                                        <FormLabelOptional label="&nbsp;" />
                                        <NJVButton
                                            size="large"
                                            type="primary"
                                            onClick={() => this.downloadShipperOrderExcel()}
                                            style={{ width: '100%', backgroundColor: 'green' }} text="Export Excel" />
                                    </Col>
                                </Row>
                            </NJVCard>
                        </Col>
                    </Row>
                    <Row>

                        {
                            isDataFetching ?
                                <Col span={24}>
                                    <Skeleton active />
                                </Col>
                                :
                                <Col span={24}>
                                    <Table
                                        style={{ borderRadius: 10 }}

                                        pagination={{
                                            position: 'top' | 'bottom',
                                            total: totalPage,
                                            current: page,
                                            onChange: this.pagination,
                                            defaultPageSize: pageSize,
                                            showSizeChanger: false
                                        }}
                                        columns={columns}
                                        dataSource={data}
                                        rowClassName={getRowClassName}
                                    />
                                </Col>

                        }
                    </Row>
                </Col>
            </Row >
        )
    }
}

export default compose(withRouter)(StoreAdminOrderHistory);