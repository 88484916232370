import { Col, Divider, Row } from 'antd'
import React, { Component } from 'react'
import { NJVNoServiceCard } from '../../component/core-component'
const non_service_image = require('../../asset/non-service-image.jpg')
const coverage_image = require('../../asset/coverage-image.jpg')

// const cardImg = require('../../asset/image.png')

// const images = [
//   { 
//     itemImage: cardImg,
//     name: "တည်ဆဲဥပဒေများနှင့်ဆန့်ကျင်သော ပစ္စည်းများ"
//   },
//   {
//     itemImage: cardImg,
//     name: "နိုင်ငံတော်အစိုးရကို အကြည်ညိုပျက်စေမည့်စာအုပ်စာတမ်းများ"
//   },
//   {
//     itemImage: cardImg,
//     name: "နိုင်ငံတော်လုံခြုံရေးနှင့်တရားဥပဒေစိုးမိုးရေးကို ထိခိုက်စေသည့်အားပေးလှုံဆော်စာများ"
//   },
//   {
//     itemImage: cardImg,
//     name: "မူးယစ်ဆေးဝါးနှင့် စိတ်ကိုပြောင်းလဲစေတတ်သောပစ္စည်းများ"
//   },
//   { 
//     itemImage: cardImg,
//     name: "အဆိပ်အတောက်ဖြစ်စေသောပစ္စည်းများ"
//   },
//   {
//     itemImage: cardImg,
//     name: "အမြင်မတော်သောအကျင့်စာရိတ္တကိုပျက်စီးစေနိုင်သောပစ္စည်းများ"
//   },
//   {
//     itemImage: cardImg,
//     name: "ပုပ်သိုးပျက်စီးနိုင်သော အစားအသောက်များ"
//   },
//   {
//     itemImage: cardImg,
//     name: "Credit Cards, ATM Cards နှင့်အရေးကြီးသောစာရွက်စာတမ်းများ"
//   },
//   {
//     itemImage: cardImg,
//     name: "အဖုံးမလုံသော ယိုစိမ့် စီးနိုင်သောပစ္စည်းများ"
//   },
//   {
//     itemImage: cardImg,
//     name: "မူးယစ်စေသော အဖျော်ယမကာပစ္စည်းများ"
//   },
//   {
//     itemImage: cardImg,
//     name: "ဘာသာရေးနှင့်ဆိုင်သောပစ္စည်းများ"
//   },
//   {
//     itemImage: cardImg,
//     name: "လက်နက်ခဲယမ်းများ၊ ဖောက်ခွဲရေးပစ္စည်းများ၊ စစ်ဘက်ဆိုင်ရာသုံးပစ္စည်းများ"
//   },
//   {
//     itemImage: cardImg,
//     name: "သက်ရှိသတ္တဝါများ"
//   },
//   {
//     itemImage: cardImg,
//     name: "နိုင်ငံတော်မှ ထုတ်ပြန်ထားသည့်ထီလက်မှတ်များမှအပ အခြားထီလက်မှတ်များ"
//   },
//   {
//     itemImage: cardImg,
//     name: "လုံခြုံစွာထုပ်ပိုးချည်နှောင်ထားခြင်းမရှိသောချွန်ထက်သည့်ပစ္စည်းများနှင့် ကွဲလွယ်သောပစ္စည်းများ"
//   },
//   {
//     itemImage: cardImg,
//     name: "ရွှေ၊ငွေ ကျောက်မျက်ရတနာနှင့် လက်ဝတ်ရတနာများ"
//   },
//   {
//     itemImage: cardImg,
//     name: "ငွေအကြွေနှင့်ငွေစက္ကူများ၊ အခြားတန်ဖိုးကြီးပစ္စည်းများ"
//   },
//   {
//     itemImage: cardImg,
//     name: "အဆောင် ယတြာပစ္စည်းများ"
//   },
//   {
//     itemImage: cardImg,
//     name: "မြန်မာ့‌ရှေး‌ဟောင်းယဥ်ကျေးမှုအမွေအနှစ်များ"
//   },
//   {
//     itemImage: cardImg,
//     name: "ပေါက်ကွဲစေတတ်သော၊ မီးလောင်လွယ်သောရေဒီယိုသတ္တိကြွသောပစ္စည်းများနှင့် အခြားအန္တရာယ်ရှိသောပစ္စည်းများ"
//   },
// ]

class NoneServiceItem extends Component {
  render() {
    return (
      <>
        <Row>
          <Col span={1} />
          <Col span={22}>
            {/* <Row>
              <Col span={24}>
                  <TitleLevel3 label={i18next.t("none_service_item")} />
              </Col>
            </Row> */}
            {/* <Row gutter={[16, 24]} style={{ marginTop: '20px'}}>
              {
                images.map((img,index) => (
                  <Col xl={4} lg={6} md={8} sm={8} xs={12} key={index}>
                    <NJVNoServiceCard bordered={false} style={{ display:'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center'}}
                    cover={
                      <div style={{ overflow: "hidden",width:'120px',height: '120px',borderRadius: '50%', border: '3px solid #c2002f' }}>
                        <img
                          alt="example"
                          style={{ height: "100%" }}
                          src={img.itemImage}
                        />
                      </div>
                    }>
                      <Meta
                        description={<h5 style={{ color: '#c2002f',fontSize: '16px'}}>{img.name}</h5>}
                      />
                    </NJVNoServiceCard>
              </Col>
                ))
              }
            </Row> */}
            <Row>
              <Col span={1} />
              <Col span={22}>
                <Row gutter={[48, 48]}>
                  <Col span={24}>
                    <NJVNoServiceCard style={{ overflow: 'hidden' }} bordered={false} cover={<img src={non_service_image} />} />
                  </Col>
                  <Divider />
                  <Col span={24}>
                    <NJVNoServiceCard style={{ overflow: 'hidden' }} bordered={false} cover={<img src={coverage_image} />} />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </>
    )
  }
}
export default NoneServiceItem