export const ShipperPasswordValidator = (_, value) => {
    if (!value || value.length < 8) {
        return Promise.reject('Password must be at least 8 characters');
    }
    return Promise.resolve();
};


export const AdminPasswordValidator = (_, value) => {
    if (!value || value.length < 8) {
        return Promise.reject('Password must be at least 8 characters');
    }
    if (!/[!@#$%^&*()_+{}[\]:;<>,.?~\\-]/.test(value)) {
        return Promise.reject('Password must contain at least one special character');
    }
    return Promise.resolve();
};

export const PhoneNumberValidator = (_, value) => {
    const numberPattern = /^[0-9]+$/;
    if (!value || value.length < 8 || value.length > 11 || !numberPattern.test(value)) {
        return Promise.reject('Invalid Phone Number');
    }
    return Promise.resolve();
};

export const PostalCodeValidator = (_, value) => {
    const numberPattern = /^[0-9]+$/;
    if (!value || value.length < 5 || value.length > 5 || !numberPattern.test(value)) {
        return Promise.reject('Invalid postal code');
    }
    return Promise.resolve();
};

export const NRCValidator = (_, value) => {
    let isNumber = false;
    const numberPattern = /^[0-9]+$/;
    if (!value || value.length < 6 || value.length > 6 || !numberPattern.test(value)) {
        return Promise.reject('Invalid NRC Number');
    }
    return Promise.resolve();
};


export const NumberOnlyValidator = (_, value) => {
    const numberPattern = /^[0-9]+$/;
    if (!value || value.length < 6 || value.length > 17 || !numberPattern.test(value)) {
        return Promise.reject('Invalid input');
    }
    return Promise.resolve();
};

export const NumberOnlyForOptionalValidator = (_, value) => {
    const numberPattern = /^[0-9]+$/;
    let isValueExist = false
    if (value && (value.length < 6 || value.length > 17)) {
        return Promise.reject('Invalid input');
    }
    if (value && value.length != 0) {
        isValueExist = true
    }
    if (isValueExist && !numberPattern.test(value)) {
        return Promise.reject('Invalid input');
    }
    return Promise.resolve();
};


export const NumberAndDecimelPointValidator = (_, value) => {
    let isNumber = false;
    const numberPattern = /^[0-9]*\.?[0-9]*$/;
    if (!value || !numberPattern.test(value)) {
        return Promise.reject('Invalid input');
    }
    return Promise.resolve();
};

export const NumberAndDecimelPointValidatorNullable = (_, value) => {
    let isNumber = false;
    const numberPattern = /^[0-9]*\.?[0-9]*$/;
    if (value && !numberPattern.test(value)) {
        return Promise.reject('Invalid input');
    }
    return Promise.resolve();
};

