const accessTokenKey = 'nvmpartner-access-token'
const refreshTokenKey = 'nvmpartner-refresh-token'
const authenticated = 'nvmpartner-authenticated'
const userTypeKey = 'nvmpartner-user-type'

const encodeValue = (value) => {
    return btoa(value);
};
const decodeValue = (encodedValue) => {
    return atob(encodedValue);
};
export const LOC_storeToken = (data) => {
    if (data) {
        const encodedAccessToken = encodeValue(data.accessToken);
        const encodedRefreshToken = encodeValue(data.refreshToken);
        localStorage.setItem(accessTokenKey, encodedAccessToken);
        localStorage.setItem(refreshTokenKey, encodedRefreshToken);
        if (data && data.accessToken) {
            localStorage.setItem(authenticated, true);
        }
    }
}
export const LOC_storeUserType = (data) => {
    const encodedUserType = encodeValue(data);
    localStorage.setItem(userTypeKey, encodedUserType);
}
export const LOC_getAccessToken = () => {
    const accessToken = localStorage.getItem(accessTokenKey)
    let decodeToken = null
    if (accessToken && accessToken !== null && accessToken !== undefined) {
        decodeToken = decodeValue(accessToken)
    }
    return decodeToken;
}
export const LOC_getUserType = () => {
    const userType = localStorage.getItem(userTypeKey)
    let decodedUserType = null
    if (userType && userType !== null && userType !== undefined) {
        decodedUserType = decodeValue(userType)
    }
    return decodedUserType;
}
export const LOC_clear_token = () => {
    localStorage.clear()
}
export const LOC_authenticate = () => localStorage.getItem(authenticated)

export const LOC_getRefreshToken = () => decodeValue(localStorage.getItem(refreshTokenKey));
