import { DeleteOutlined, LoadingOutlined } from '@ant-design/icons'
import { compose } from '@reduxjs/toolkit'
import { Col, Row, Spin, message } from 'antd'
import i18next from 'i18next'
import React, { Component } from 'react'
import Webcam from 'react-webcam'
import { FormLabel, FormLabelOptional, NJVButton, NJVCard, NJVFloatButton, NJVInput, NJVSelect, NJVTextarea, TitleLevel3 } from '../../component/core-component'
import { SvgCameraViewFinderFilled } from '../../component/custom-svgIcon'
import Api from '../../network/api'
import { ApiInstance } from '../../network/api-manager'
import { HTTP_METHOD } from '../../network/httpMethod'
import { MEDIA_TYPE } from '../../network/mediaType'
import withRouter from '../../network/with-router'
import CustomPath from '../../routes/custom-path'

const camWidth = 279
const camHeight = 210

class AdvanceOrderForm extends Component {

    constructor(props) {
        super(props)
        this.state = {
            senderTownshipList: [],
            receiverTownshipList: [],
            divisionList: [],
            orderData: { orderType: 1, note: null },
            deliveryFees: 0,
            division_collection_home_delivery: [],
            township_map_home_delivery: {},
            isFetchingPriceScript: false,
            isFinishFetchingData: false,
            fail_message_code: '076',
            showOrderCancelConfirmModal: false,
            orderList: [],
            isOpenFirstCamera: false,
            isOpenSecondCamera: false,
            frontImage: null,
            productImage: null,
            showNRCCameraLoading: false,
            showProductCameraLoading: false,
            showAWBModel: false
        }
        this.webcamRef = React.createRef();
        this.productWebcamRef = React.createRef();
        this.photoConstraints = {
            width: 279,
            height: 210,
        }
    }

    componentDidMount() {
        this.fetchDivision();
    }

    fetchDivision = async () => {
        const { orderData } = this.state
        try {
            const response = await ApiInstance(Api.division_get_all, HTTP_METHOD.GET, MEDIA_TYPE.JSON);
            if (response) {
                this.setState({
                    division_collection: response,
                    isFinishFetchingData: true
                })
                let divisionListHomeDelivery = []
                let townshipMapHomeDelivery = {}

                response?.map(division => {
                    if (division.active && division.enableHomeDelivery) {
                        divisionListHomeDelivery.push(division)
                        let townshipListHomeDelivery = []
                        division.townshipDTOList && division.townshipDTOList.map(township => {
                            if (township.active && township.enableHomeDelivery) {
                                townshipListHomeDelivery.push(township)
                            }
                        })
                        townshipMapHomeDelivery[division.id] = townshipListHomeDelivery
                    }
                })
                this.setState({
                    divisionList: divisionListHomeDelivery,
                    isFinishFetchingData: false,
                    division_collection_home_delivery: divisionListHomeDelivery,
                    township_map_home_delivery: townshipMapHomeDelivery,
                }, () => {
                    const senderId = this.props.senderDivision?.id;
                    const divisionObj = { value: this.props.senderDivision?.id, label: this.props.senderDivision?.name, type: 'division' }
                    const senderTownshipList = townshipMapHomeDelivery[senderId];
                    this.setState({
                        senderTownshipList: senderTownshipList,
                        orderData: { ...orderData, senderDivision: divisionObj }
                    });
                })

            }
        } catch (error) {
        }
    }

    getPriceScript = async (senderTownshipId, receiverTownshipId) => {
        try {
            const params = {
                'senderTownshipId': senderTownshipId ? senderTownshipId : 0,
                'receiverTownshipId': receiverTownshipId ? receiverTownshipId : 0
            }
            const response = await ApiInstance(Api.get_price, HTTP_METHOD.GET, MEDIA_TYPE.JSON, params)
            if (response || response === 0) {
                this.setState({
                    // orderData: { ...orderData, deliveryFees: response }
                    deliveryFees: response
                })
            }
        } catch (error) {

        }
        this.setState({
            isFetchingPriceScript: false
        })
    }

    handleUserData = (key, value) => {
        let { township_map_home_delivery, orderData } = this.state;
        let senderTownshipList = [];
        let receiverTownshipList = [];
        orderData = {
            ...orderData,
            [key]: value
        }

        if (key === "senderTownship" && value?.value) {
            if (orderData?.receiverTownship?.value) {
                this.setState({ isFetchingPriceScript: true }, () => {
                    this.getPriceScript(this.props.senderTownship, orderData?.receiverTownship?.value);
                });
            }
        } else if (key === "receiverTownship" && value?.value) {
            if (orderData?.senderTownship?.value) {
                this.setState({ isFetchingPriceScript: true }, () => {
                    this.getPriceScript(this.props.senderTownship, value?.value);
                });
            }
        } else if (key === "senderDivision") {
            senderTownshipList = township_map_home_delivery[value?.value]
            this.setState({
                senderTownshipList
            })
        } else if (key === "senderTownship") {
            senderTownshipList = township_map_home_delivery[value?.value]
            this.setState({
                senderTownshipList
            })
        } else if (key === "receiverDivision") {
            receiverTownshipList = township_map_home_delivery[value?.value]
            this.setState({
                receiverTownshipList,
            })
        } else if (key === "receiverTownship") {
            receiverTownshipList = township_map_home_delivery[value?.value]
            this.setState({
                receiverTownshipList,
            })
        } else if (key === 'senderPhoneNumber' || key === 'phoneNumber') {
            const numericValue = value.replace(/[^0-9]/g, '')
            orderData = {
                ...orderData,
                [key]: numericValue
            }
        }

        this.setState({
            orderData
        })
    }

    handleConfirmClick = () => {
        const { senderName, senderPhoneNumber, additionalAddress, senderTownship, senderDivision, receiverDivision, receiverName, receiverTownship, phoneNumber, addressDetail } = this.state.orderData;
        //For sender       

        if (!senderName) {
            message.error(`${i18next.t("err_msg_enter_sender_name")}`);
            return;
        }
        if (!senderPhoneNumber) {
            message.error(`${i18next.t("err_msg_enter_sender_phone_number")}`);
            return;
        }
        if (this.props.senderDivision.name ? !this.props.senderDivision.name : !senderDivision) {
            message.error(`${i18next.t("choose_sender_division")}`);
            return;
        }
        if (!senderTownship) {
            message.error(`${i18next.t("choose_sender_township")}`);
            return;
        }
        if (!additionalAddress) {
            message.error(`${i18next.t("err_msg_enter_sender_address")}`)
            return
        }

        // For receiver
        if (!receiverName) {
            message.error(`${i18next.t("err_msg_enter_receiver_name")}`);
            return;
        }
        if (!phoneNumber) {
            message.error(`${i18next.t("err_msg_enter_receiver_phone_number")}`);
            return;
        }
        if (!receiverDivision) {
            message.error(`${i18next.t("choose_receiver_division")}`);
            return;
        }
        if (!receiverTownship) {
            message.error(`${i18next.t("choose_receiver_township")}`);
            return;
        }
        if (!addressDetail) {
            message.error(`${i18next.t("err_msg_enter_address")}`)
            return
        }
        if (senderPhoneNumber && senderPhoneNumber.length > 5 && senderPhoneNumber.length < 12) {
        } else {
            message.error(`${i18next.t("err_msg_invalid_sender_phone_number")}`)
            return;
        }
        if (phoneNumber && phoneNumber.length > 5 && phoneNumber.length < 12) {
        } else {
            message.error(`${i18next.t("err_msg_invalid_receiver_phone_number")}`)
            return;
        }
        this.props.navigate(
            CustomPath.order_confirmation,
            { state: { orderData: { ...this.state.orderData, 'nrcFront': this.state.frontImage, 'productImage': this.state.productImage, 'deliveryFees': this.state.deliveryFees } } });
    };

    base64StringToFile = (base64String, fileName) => {
        const byteCharacters = atob(base64String.split(',')[1]);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: 'image/jpeg' });
        return new File([blob], fileName, { type: 'image/jpeg' }); 
    };

    canvasCapture = (webcamRef, type) => {
        const canvas = document.createElement('canvas');
        canvas.width = webcamRef.current.video.videoWidth;
        canvas.height = webcamRef.current.video.videoHeight;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(
            webcamRef.current.video,
            0,
            0,
            webcamRef.current.video.videoWidth,
            webcamRef.current.video.videoHeight
        );
        const imageUrl = canvas.toDataURL('image/jpeg', 1.0); // You can adjust quality here
        // Now 'imageUrl' contains the base64 representation of the captured image
        const fileName = 'image.jpg'; // Specify the filename
        const imageFile = this.base64StringToFile(imageUrl, fileName);
        if (type === 'nrcImg') {
            this.setState({ frontImage: imageFile })
        } else if (type === 'productImg') {
            this.setState({ productImage: imageFile });
        }
    };

    captureNrc = () => {
        this.canvasCapture(this.webcamRef, 'nrcImg')
    }

    captureProduct = () => {
        this.canvasCapture(this.productWebcamRef, 'productImg')
    }

    onTownshipFilterOption = (input, option) =>
        (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

    render() {
        const { divisionList, senderTownshipList, receiverTownshipList, orderData, isFetchingPriceScript, isOpenFirstCamera, isOpenSecondCamera, photoConstraints, frontImage, productImage, showNRCCameraLoading, showAWBModel, isFinishFetchingData } = this.state;
        return (
            <>
                <Row>
                    <Col lg={6} md={4} xs={1} sm={1} />
                    <Col lg={12} md={16} xs={22} sm={22}>
                        <Row style={{ display: 'flex', alignItems: 'center' }}>
                            <Col span={12} style={{ display: 'flex', alignItems: 'center', marginBottom: 20 }}>
                                <TitleLevel3 label={i18next.t("order_create")} />
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24}>
                                <NJVCard style={{ paddingBottom: 20, marginBottom: 20 }} bordered={false}>
                                    <Row gutter={[16, 16]} style={{ marginBottom: 15 }}>
                                        <Col span={24}>
                                            <h4>{i18next.t("sender_info")}</h4>
                                        </Col>
                                    </Row>
                                    <Row gutter={[16, 16]}>
                                        <Col lg={12} md={12} sm={24} xs={24}>
                                            <FormLabel label={i18next.t("sender_name")} />
                                            <NJVInput className='custom-input-bg-white' name="name" onChange={(event) => this.handleUserData('senderName', event.target.value)} size="large" />
                                        </Col>
                                        <Col lg={12} md={12} sm={24} xs={24}>
                                            <FormLabel label={i18next.t("sender_phone_number")} />
                                            <NJVInput
                                                type='text' name="phone"
                                                onChange={(event) => this.handleUserData('senderPhoneNumber', event.target.value)}
                                                value={orderData.senderPhoneNumber}
                                                size="large" placeholder="09" />
                                        </Col>
                                        <Col lg={12} md={12} sm={24} xs={24}>
                                            <FormLabel label={i18next.t("division_state")} />
                                            <NJVSelect
                                                style={{
                                                    width: '100%',
                                                }}
                                                defaultValue={this.props.senderDivision?.name}
                                                className='my-location-selector'
                                                placeholder={i18next.t("division_state")}
                                                size="large"
                                                onChange={(value, object) => this.handleUserData('senderDivision', object)}
                                                options={
                                                    divisionList && divisionList?.map((division) => {
                                                        return {
                                                            value: division.id,
                                                            label: division.name,
                                                            type: 'division'
                                                        }
                                                    })
                                                }
                                            />
                                        </Col>
                                        <Col lg={12} md={12} sm={24} xs={24}>
                                            <FormLabel label={i18next.t("township_city")} />
                                            <NJVSelect
                                                showSearch
                                                loading={isFinishFetchingData}
                                                style={{
                                                    width: '100%',
                                                }}
                                                className='my-location-selector'
                                                placeholder={i18next.t("township_city")}
                                                size="large"
                                                onChange={(value, object) => this.handleUserData('senderTownship', object)}
                                                filterOption={this.onTownshipFilterOption}
                                                options={
                                                    senderTownshipList && senderTownshipList?.map((township) => {
                                                        return {
                                                            value: township.id,
                                                            label: township.name,
                                                            type: 'township'
                                                        }
                                                    })
                                                }
                                            />
                                        </Col>
                                        <Col lg={24} md={24} sm={24} xs={24}>
                                            <FormLabel label={i18next.t("sender_address")} />
                                            <NJVInput
                                                name="address"
                                                onChange={(event) => this.handleUserData('additionalAddress', event.target.value)}
                                                style={{
                                                    width: '100%',
                                                }}
                                            />
                                        </Col>
                                        <Col lg={24} md={24} sm={24} xs={24}>
                                            <FormLabelOptional label={`${i18next.t("sender_nrc")}(Optional)`} />
                                            {
                                                !isOpenFirstCamera && !frontImage && (
                                                    <NJVCard name="photo" style={{ backgroundColor: '#fff', width: camWidth, height: camHeight, display: 'flex', wrap: 'flex-wrap', justifyContent: 'center', cursor: 'pointer', alignItems: 'center' }} bordered="false" onClick={() => this.setState({ isOpenFirstCamera: true })}>
                                                        <SvgCameraViewFinderFilled width={50} height={50} color={'gray'} />
                                                        {
                                                            showNRCCameraLoading ?
                                                                <LoadingOutlined
                                                                    style={{
                                                                        fontSize: 24,
                                                                    }}
                                                                    spin
                                                                />
                                                                :
                                                                <></>
                                                        }

                                                    </NJVCard>
                                                )
                                            }

                                            {
                                                isOpenFirstCamera && !frontImage &&
                                                <div>
                                                    <div>
                                                        <Webcam
                                                            audio={false}
                                                            imageSmoothing
                                                            width={camWidth}
                                                            height={camHeight}
                                                            ref={this.webcamRef}
                                                            screenshotFormat="image/jpeg"
                                                            photoConstraints={photoConstraints}
                                                            style={{ borderRadius: '10px', backgroundColor: '#f1f1f1' }}
                                                        />
                                                    </div>
                                                    <br />
                                                    <NJVButton type="primary" onClick={this.captureNrc} text={`${i18next.t("capture_photo")}`} style={{}} />
                                                </div>
                                            }
                                            <div style={{ display: 'flex', flexWrap: 'wrap', gap: '5px' }}>
                                                {
                                                    frontImage ?
                                                        <div style={{ position: 'relative' }}>
                                                            <img src={URL.createObjectURL(frontImage)} alt="Captured" width={280} height={200} style={{ borderRadius: '10px' }} />
                                                            <NJVFloatButton
                                                                icon={<DeleteOutlined />}
                                                                type="primary"
                                                                style={{
                                                                    position: 'absolute',
                                                                    top: '4px',
                                                                    right: '4px',
                                                                }}
                                                                onClick={() => this.setState({ frontImage: null })}
                                                            />
                                                        </div>
                                                        : <></>
                                                }
                                            </div>
                                        </Col>
                                    </Row>
                                </NJVCard>
                            </Col>
                            <Col span={24}>
                                <NJVCard style={{ paddingBottom: 20, marginBottom: 20 }} bordered={false}>
                                    <Row gutter={[16, 16]} style={{ marginBottom: 15 }}>
                                        <Col span={24}>
                                            <h4>{i18next.t("receiver_info")}</h4>
                                        </Col>
                                    </Row>
                                    <Row gutter={[16, 16]}>
                                        <Col lg={12} md={12} sm={24} xs={24}>
                                            <FormLabel label={i18next.t("receiver_name")} />
                                            <NJVInput className='custom-input-bg-white' name="name" onChange={(event) => this.handleUserData('receiverName', event.target.value)} size="large" />
                                        </Col>
                                        <Col lg={12} md={12} sm={24} xs={24}>
                                            <FormLabel label={i18next.t("receiver_phone_number")} />
                                            <NJVInput
                                                type='text'
                                                name="phone"
                                                onChange={(event) => this.handleUserData('phoneNumber', event.target.value)} size="large" placeholder="09"
                                                value={orderData.phoneNumber} />
                                        </Col>
                                        <Col lg={12} md={12} sm={24} xs={24}>
                                            <FormLabel label={i18next.t("division_state")} />
                                            <NJVSelect
                                                style={{
                                                    width: '100%',
                                                }}
                                                className='my-location-selector'
                                                placeholder={i18next.t("division_state")}
                                                size="large"
                                                onChange={(value, object) => this.handleUserData('receiverDivision', object)}
                                                options={
                                                    divisionList && divisionList?.map((division) => {
                                                        return {
                                                            value: division.id,
                                                            label: division.name,
                                                            type: 'division'
                                                        }
                                                    })
                                                }
                                            />
                                        </Col>
                                        <Col lg={12} md={12} sm={24} xs={24}>
                                            <FormLabel label={i18next.t("township_city")} />
                                            <NJVSelect
                                                showSearch
                                                style={{
                                                    width: '100%',
                                                }}
                                                className='my-location-selector'
                                                placeholder={i18next.t("township_city")}
                                                size="large"
                                                onChange={(value, object) => this.handleUserData('receiverTownship', object)}
                                                filterOption={this.onTownshipFilterOption}
                                                options={
                                                    receiverTownshipList && receiverTownshipList?.map((township) => {
                                                        return {
                                                            value: township.id,
                                                            label: township.name,
                                                            type: 'township'
                                                        }
                                                    })
                                                }
                                            />
                                        </Col>
                                        <Col lg={24} md={24} sm={24} xs={24}>
                                            <FormLabel label={i18next.t("receiver_address")} />
                                            <NJVInput
                                                name="address" onChange={(event) => this.handleUserData('addressDetail', event.target.value)}
                                                style={{
                                                    width: '100%',
                                                }}
                                            />
                                        </Col>
                                        <Col span={24} md={24} sm={24} xs={24}>
                                            <FormLabelOptional label={i18next.t("note")} />
                                            <NJVTextarea
                                                name="notes" onChange={(event) => this.handleUserData('note', event.target.value)}
                                                maxLength={100}
                                                style={{ height: 60 }}
                                            />
                                        </Col>
                                        <Col span={24} md={24} sm={24} xs={24}>
                                            <div style={{ height: 40, display: 'flex', alignItems: 'center' }}>
                                                <p>{i18next.t("delivery_fees")}</p>
                                                {
                                                    isFetchingPriceScript ?
                                                        <Spin
                                                            indicator={
                                                                <LoadingOutlined
                                                                    style={{
                                                                        fontSize: 24,
                                                                    }}
                                                                    spin
                                                                />
                                                            }
                                                        />
                                                        :
                                                        <>- {this.state.deliveryFees} MMK</>
                                                }
                                            </div>
                                        </Col>
                                        <Col lg={24} md={24} sm={24} xs={24}>
                                            <FormLabelOptional label={`${i18next.t("product_image")}(Optional)`} />
                                            {
                                                !isOpenSecondCamera && (!productImage) && (
                                                    <NJVCard name="photo" style={{ backgroundColor: '#fff', width: camWidth, height: camHeight, padding: '10px', display: 'flex', wrap: 'flex-wrap', justifyContent: 'center', cursor: 'pointer', alignItems: 'center' }} bordered="false" onClick={() => this.setState({ isOpenSecondCamera: true })}>
                                                        <SvgCameraViewFinderFilled width={50} height={50} color={'gray'} />
                                                    </NJVCard>
                                                )
                                            }
                                            {
                                                isOpenSecondCamera && (!productImage) &&
                                                <div>
                                                    <Webcam
                                                        audio={false}
                                                        width={this.photoConstraints.width}
                                                        height={this.photoConstraints.height}
                                                        ref={this.productWebcamRef}
                                                        screenshotQuality={1}
                                                        screenshotFormat="image/jpeg"
                                                        photoConstraints={photoConstraints}
                                                        style={{ borderRadius: '10px', backgroundColor: '#f1f1f1' }}
                                                    />
                                                    <br />
                                                    <NJVButton type="primary" onClick={this.captureProduct} text={`${i18next.t("capture_photo")}`} style={{ alignSelf: 'end' }} />
                                                </div>
                                            }
                                            <div style={{ display: 'flex', flexWrap: 'wrap', gap: '5px' }} name="productImg">
                                                {
                                                    productImage ?
                                                        <div style={{ position: 'relative' }}>
                                                            <img src={URL.createObjectURL(productImage)} alt="Captured" width={280} height={200} style={{ borderRadius: '10px' }} />
                                                            <NJVFloatButton
                                                                icon={<DeleteOutlined />}
                                                                type="primary"
                                                                style={{
                                                                    position: 'absolute',
                                                                    top: '4px',
                                                                    right: '4px',
                                                                }}
                                                                onClick={() => this.setState({ productImage: null })}
                                                            />
                                                        </div>
                                                        : <></>
                                                }
                                            </div>
                                        </Col>
                                        <Col lg={24} md={24} sm={24} xs={24}>
                                            <NJVButton
                                                loading={isFetchingPriceScript}
                                                size='large' style={{ width: '100%' }} type='primary' onClick={() => this.handleConfirmClick()} text=
                                                {i18next.t("confirm")}
                                            />
                                        </Col>
                                    </Row>
                                </NJVCard>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </>
        )
    }
}

export default compose(withRouter)(AdvanceOrderForm)