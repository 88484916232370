import { Typography } from "antd";

const { Title } = Typography

export const FormLabel = (props) => {
    const { label } = props;
    return (
        <div style={{ marginBottom: 5 }}>
            <span style={{ color: "red" }}>*</span> <span>{label}</span>
        </div>

    );
}


export const FormLabelOptional = (props) => {
    const { label } = props;
    return (
        <div style={{ marginBottom: 5 }}>
            <span>{label}</span>
        </div>

    );
}


export const ErrorLabel = (props) => {
    const { label } = props;
    return (
        <div style={{ marginLeft: 5 }}>
            <span style={{ color: "red" }}>( *required )</span>
        </div>

    );
}

export const TitleLevel3 = (props) => {
    const { label } = props;
    return (
        <Title level={3}>{label}</Title>
    );
}

export const TitleLevel4 = (props) => {
    const { label } = props;
    return (
        <Title level={4}>{label}</Title>
    );
}

