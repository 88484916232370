import { SvgAWB, SvgFAQOutlined, SvgHistoryFilled, SvgHistoryOutlined, SvgInvoiceOutlined, SvgLearningOutlined, SvgLocationOutlined, SvgNoServiceFilled, SvgNotificationBellFilled, SvgNotificationOutlined, SvgOrderDollyOutlined, SvgPrivacyPolicy, SvgROM, SvgRTS, SvgReportFilled, SvgStoreFilled, SvgStoreOutlined, SvgTandCOutlined } from "../component/custom-svgIcon";

export const GenerateSvgIconBaseOnName = (name, selectedIndex, currentIndex) => {
    let svgIcon;
    let isSelect = selectedIndex === currentIndex ? true : false
    if (name === 'order_create') {
        svgIcon = <SvgOrderDollyOutlined width={20} height={20} color={isSelect ? 'white' : 'gray'} />
    } else if (name === 'rom') {
        svgIcon = <SvgROM width={20} height={20} color={isSelect ? 'white' : 'gray'} />
    } else if (name === 'rts') {
        svgIcon = <SvgRTS width={25} height={25} color={isSelect ? 'white' : 'gray'} />
    } else if (name === 'order_history') {
        svgIcon = <SvgHistoryFilled width={20} height={20} color={isSelect ? 'white' : 'gray'} />
    } else if (name === 'awb') {
        svgIcon = <SvgAWB width={20} height={20} color={isSelect ? 'white' : 'gray'} />
    } else if (name === 'fav_location') {
        svgIcon = <SvgLocationOutlined width={20} height={20} color={isSelect ? 'white' : 'gray'} />
    } else if (name === 'notification_history') {
        svgIcon = <SvgNotificationBellFilled width={20} height={20} color={isSelect ? 'white' : 'gray'} />
    } else if (name === 'invoice') {
        svgIcon = <SvgInvoiceOutlined width={20} height={20} color={isSelect ? 'white' : 'gray'} />
    } else if (name === 'guide') {
        svgIcon = <SvgLearningOutlined width={20} height={20} color={isSelect ? 'white' : 'gray'} />
    } else if (name === 'faq') {
        svgIcon = <SvgFAQOutlined width={20} height={20} color={isSelect ? 'white' : 'gray'} />
    } else if (name === 'terms_and_condition') {
        svgIcon = <SvgTandCOutlined width={20} height={20} color={isSelect ? 'white' : 'gray'} />
    } else if (name === 'privacy_and_policy') {
        svgIcon = <SvgPrivacyPolicy width={20} height={20} color={isSelect ? 'white' : 'gray'} />
    } else if (name === 'store') {
        svgIcon = <SvgStoreFilled width={20} height={20} color={isSelect ? 'white' : 'gray'} />
    } else if (name === 'report') {
        svgIcon = <SvgReportFilled width={20} height={20} color={isSelect ? 'white' : 'gray'} />
    } else if (name === 'none_service_item'){
        svgIcon = <SvgNoServiceFilled width={20} height={20} color={isSelect ? 'white' : 'gray'} /> 
    }
    return (svgIcon)
}