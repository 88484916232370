import { compose } from '@reduxjs/toolkit';
import { Card, Col, Row, Skeleton, Statistic, message } from "antd";
import i18next from 'i18next';
import React, { Component } from 'react';
import CountUp from 'react-countup';
import { FormLabelOptional, NJVButton, NJVCard, NJVDatePicker, NJVInput, NJVReportTable, NJVSelect } from '../component/core-component';
import Theme from '../component/theme';
import Api from '../network/api';
import { ApiInstance, downloadExcel } from '../network/api-manager';
import { HTTP_METHOD } from '../network/httpMethod';
import { MEDIA_TYPE } from '../network/mediaType';
import withRouter from "../network/with-router";
import { updateSelectedMenu } from '../redux/cookie-slice';
import CustomPath from '../routes/custom-path';
import { updateProfile } from '../redux/profile-slice';
const empty_history_image = require('../asset/shipper_history_empty.png')

const formatter = (value) => <CountUp end={value} separator=',' />

// export const TotalDisplay = ({title,count}) => (
//     <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between', alignItems: 'center', fontSize: '20px',backgroundColor: '#f5f5f5',padding: '10px',marginBottom: '10px',borderRadius: '10px' }}>
//         <p style={{ margin: '5px',fontWeight: '600',fontSize: '20px'}}>{title}</p>
//         <p style={{ margin: 0,fontWeight: 'bold', color: '#c2002f', borderRadius: '10px', padding: '5px 10px', backgroundColor: '#fff' }}>{count}</p>
//     </div>
// )

export const StatisticCard = ({ name, value }) => (
    <Card
        style={{ backgroundColor: Theme.colors.card_bg_color, boxShadow: 'none', borderRadius: 20 }}
        bordered={false}>
        <Statistic
            title={<span style={titlfontStyle}>{name}</span>}
            value={value}
            valueStyle={{
                color: '#3f8600',
            }}
            formatter={formatter}
        />
    </Card>
)

const titlfontStyle = {
    fontWeight: '600',
    fontSize: 16
}

class AdminDashboardPage extends Component {
    constructor(props) {
        super(props)
        this.state = {
            data: [],
            totalCommission: 0,
            totalRevenue: 0,
            totalHandOnParcel: 0,
            totalOngoingParcel: 0,
            totalCompleteParcel: 0,
            totalCancelParcel: 0,
            totalParcel: 0,
            storeReportsList: [],
            storeNameList: [],
            handOn_list: [],
            onGoing_list: [],
            complete_list: [],
            cancel_list: [],
            revenue_list: [],
            commission_list: [],
            isFetchingData: false,
            filterData: {},
            isDownloadExcel: false,
            isSearchingData: false
        }
    }

    componentDidMount() {
        this.fetchData()
        this.fetchProfile()
        this.fetchStoreName()
        this.props.dispatch(updateSelectedMenu("14"))
    }

    fetchProfile = async () => {
        try {
            const response = await ApiInstance(Api.partnership_profile, HTTP_METHOD.GET, MEDIA_TYPE.JSON);
            if (response) {
                this.props.dispatch(updateProfile(response));
            }
        } catch (error) {
        }
    }


    handleFilter = (event) => {
        this.setState({ filter_name: event.target.value })
    }

    trackingSearch = async () => {
        const { filter_name } = this.state
        if (!filter_name || filter_name.length < 15) {
            message.error("Please enter tracking number first.")
            return
        }
        const data = {
            id: filter_name,
            route: CustomPath.dashboard
        }
        this.props.navigate(
            CustomPath.order_detail,
            { state: { data: data } }
        );
    }

    fetchStoreName = async () => {
        try {
            const response = await ApiInstance(Api.partnership_storename, HTTP_METHOD.GET, MEDIA_TYPE.JSON)
            if (response) {
                let storeNameList = []
                response.map((res) => {
                    storeNameList.push({
                        value: res.storeId,
                        label: res.storeName,
                    });
                })
                this.setState({
                    storeNameList
                })
            }
        } catch (error) {
        }
    }

    fetchData = async () => {
        let { filterData } = this.state;

        try {
            this.setState({
                isFetchingData: true,
            })

            let params = {}
            const { fromDate, toDate } = filterData;

            if (filterData) {
                if ((fromDate && !toDate) || (!fromDate && toDate) || (fromDate > toDate)) {
                    message.error("Invalid date")
                    return
                }
                params = {
                    ...filterData,
                    fromDate: fromDate ? fromDate.format("YYYY-MM-DD") : undefined,
                    toDate: toDate ? toDate.format("YYYY-MM-DD") : undefined,
                }
            }
            const response = await ApiInstance(Api.partnership_admin_report, HTTP_METHOD.GET, MEDIA_TYPE.JSON, params);
            if (response) {
                const { totalCommission, totalRevenue, totalHandOnParcel, totalOngoingParcel, totalCompleteParcel, totalCancelParcel, storeReports, totalParcel } = response;
                this.setState({
                    data: storeReports,
                    totalParcel,
                    totalCommission,
                    totalRevenue,
                    totalHandOnParcel,
                    totalOngoingParcel,
                    totalCompleteParcel,
                    totalCancelParcel,
                    storeReportsList: response.storeReports,
                })
                let handOn_list = [];
                let onGoing_list = [];
                let complete_list = [];
                let cancel_list = [];
                let revenue_list = [];
                let commission_list = [];

                storeReports.forEach(report => {
                    this.addToListIfValid(report, 'handOnParcel', handOn_list)
                    this.addToListIfValid(report, 'ongoingParcel', onGoing_list);
                    this.addToListIfValid(report, 'completeParcel', complete_list);
                    this.addToListIfValid(report, 'cancelParcel', cancel_list);
                    this.addToListIfValid(report, 'totalRevenue', revenue_list);
                    this.addToListIfValid(report, 'totalCommission', commission_list);
                });
                this.setState({
                    handOn_list,
                    onGoing_list,
                    cancel_list,
                    complete_list,
                    revenue_list,
                    commission_list
                })
            }
        } catch (error) {
            console.log(error);
        } finally {
            this.setState({
                isFetchingData: false,
                isSearchingData: false,
            });
        }
    }

    addToListIfValid = (report, key, list) => {
        if (report[key] && report[key] !== 0) {
            list.push(
                {
                    storeName: report.storeName,
                    [key]: report[key]
                }
            )
        }
    }

    search = () => {
        this.setState({
            isSearchingData: true
        }, () => {
            this.fetchData()
        })
    }

    updateFilterData = (key, value) => {
        let { filterData } = this.state
        filterData = {
            ...filterData,
            [key]: value
        }
        this.setState({
            filterData: filterData
        })
    }

    downloadPartnershipReport = async () => {
        let { filterData } = this.state
        this.setState({
            isDownloadExcel: true
        })
        try {
            let params;
            let { fromDate, toDate } = filterData;
            if (filterData) {
                if ((fromDate && !toDate) || (!fromDate && toDate) || (fromDate > toDate)) {
                    message.error("Invalid date")
                    return
                }
                params = {
                    ...filterData,
                    fromDate: fromDate ? fromDate.format("YYYY-MM-DD") : undefined,
                    toDate: toDate ? toDate.format("YYYY-MM-DD") : undefined,
                }
            }
            await downloadExcel(Api.partner_export, params, "Partnership Report")
        } catch (error) {
        }
        this.setState({
            isDownloadExcel: false
        })
    }


    render() {
        const { data, totalRevenue, isFetchingData, totalCommission, totalHandOnParcel, totalOngoingParcel, totalCancelParcel, totalCompleteParcel, totalParcel, storeNameList, isDownloadExcel, isSearchingData } = this.state
        // const handOnColumn = [
        //     {
        //         title: 'No.',
        //         dataIndex: 'index',
        //         key: 'index',
        //         render: (value, _, index) => index + 1
        //     },
        //     {
        //         title: 'Store Name',
        //         dataIndex: 'storeName',
        //         key: 'name',
        //     },
        //     {
        //         title: 'Handon Parcel',
        //         dataIndex: 'handOnParcel',
        //         key: 'handon',
        //         align: 'center'
        //     },
        // ];

        // const onGoingColumn = [
        //     {
        //         title: 'No.',
        //         dataIndex: 'index',
        //         key: 'index',
        //         render: (value, _, index) => index + 1
        //     },
        //     {
        //         title: 'Store Name',
        //         dataIndex: 'storeName',
        //         key: 'name',
        //     },
        //     {
        //         title: 'In Progress Parcel',
        //         dataIndex: 'ongoingParcel',
        //         key: 'progress',
        //         align: 'center'
        //     }
        // ]

        // const completeColumn = [
        //     {
        //         title: 'No.',
        //         dataIndex: 'index',
        //         key: 'index',
        //         render: (value, _, index) => index + 1
        //     },
        //     {
        //         title: 'Store Name',
        //         dataIndex: 'storeName',
        //         key: 'name',
        //     },
        //     {
        //         title: 'complete Order',
        //         dataIndex: 'completeParcel',
        //         key: 'complete',
        //         align: 'center'
        //     },
        // ]

        // const cancelColumn = [
        //     {
        //         title: 'No.',
        //         dataIndex: 'index',
        //         key: 'index',
        //         render: (value, _, index) => index + 1
        //     },
        //     {
        //         title: 'Store Name',
        //         dataIndex: 'storeName',
        //         key: 'name',
        //     },
        //     {
        //         title: 'Cancel Order',
        //         dataIndex: 'cancelParcel',
        //         key: 'cancel',
        //         align: 'center'
        //     },
        // ]

        // const revenueColumn = [
        //     {
        //         title: 'No.',
        //         dataIndex: 'index',
        //         key: 'index',
        //         render: (value, _, index) => index + 1
        //     },
        //     {
        //         title: 'Store Name',
        //         dataIndex: 'storeName',
        //         key: 'name',
        //     },
        //     {
        //         title: 'Total Revenue',
        //         dataIndex: 'totalRevenue',
        //         key: 'revenue',
        //         align: 'center'
        //     },
        // ]

        // const commissionColumn = [
        //     {
        //         title: 'No.',
        //         dataIndex: 'index',
        //         key: 'index',
        //         render: (value, _, index) => index + 1
        //     },
        //     {
        //         title: 'Store Name',
        //         dataIndex: 'storeName',
        //         key: 'name',
        //     },
        //     {
        //         title: 'Total Commission',
        //         dataIndex: 'totalCommission',
        //         key: 'commission',
        //         align: 'center'
        //     },
        // ]

        const column = [
            {
                title: 'No.',
                dataIndex: 'index',
                key: 'index',
                render: (value, _, index) => index + 1
            },
            {
                title: 'Store Name',
                dataIndex: 'storeName',
                key: 'name',
            },
            {
                title: 'In-store Parcel',
                dataIndex: 'handOnParcel',
                key: 'handon',
                align: 'center'
            },
            {
                title: 'In Progress Parcel',
                dataIndex: 'ongoingParcel',
                key: 'progress',
                align: 'center'
            },
            {
                title: 'Complete Parcel',
                dataIndex: 'completeParcel',
                key: 'complete',
                align: 'center'
            },
            {
                title: 'Cancel Parcel',
                dataIndex: 'cancelParcel',
                key: 'cancel',
                align: 'center'
            },
            {
                title: 'Created Parcel',
                dataIndex: 'totalParcel',
                key: 'totalParcel',
                align: 'center'
            },
            {
                title: 'Total Revenue',
                dataIndex: 'totalRevenue',
                key: 'revenue',
                align: 'center'
            },
            {
                title: 'Total Commission',
                dataIndex: 'totalCommission',
                key: 'commission',
                align: 'center',
                width: '5%'
            },
        ];

        const getRowClassName = (record, index) => {
            return index % 2 === 0 ? 'blue-row' : 'black-row';
        };

        return (
            <Row>
                <Col span={1} />
                <Col span={22}>
                    <Row>
                        <Col span={24}>
                            <Row gutter={16}>
                                <Col lg={20} md={20} sm={18} xs={18}>
                                    <NJVInput
                                        size='large'
                                        style={{ backgroundColor: '#f5f5f5' }} placeholder="Enter tracking id" onChange={this.handleFilter} />
                                </Col>
                                <Col lg={4} md={4} sm={6} xs={6}>
                                    <NJVButton size="large" type="primary" style={{ backgroundColor: Theme.colors.primary, width: '100%' }} onClick={() => this.trackingSearch()} text={i18next.t("search")} />
                                </Col>
                            </Row>
                        </Col>
                        <Col span={24} style={{ marginTop: '20px' }}>
                            <NJVCard
                                style={{ paddingTop: 15, paddingBottom: 15 }}
                                bordered={false}
                            >
                                <Row gutter={[16, 16]}>
                                    <Col span={6}>
                                        <FormLabelOptional label={i18next.t("store_name")} />
                                        <NJVSelect
                                            onChange={(value) => this.updateFilterData('storeId', value)}
                                            size="large"
                                            allowClear={true}
                                            style={{ width: '100%' }}
                                            placeholder={i18next.t("store_name")}
                                            options={storeNameList}
                                        >
                                        </NJVSelect>
                                    </Col>
                                    <Col span={6}>
                                        <FormLabelOptional label={i18next.t("from_date")} />
                                        <NJVDatePicker
                                            size="large"
                                            onChange={(value) => this.updateFilterData('fromDate', value)}
                                            style={{ width: '100%' }}
                                            placeholder={i18next.t("from_date")} />
                                    </Col>
                                    <Col span={6}>
                                        <FormLabelOptional label={i18next.t("to_date")} />
                                        <NJVDatePicker
                                            size="large"
                                            onChange={(value) => this.updateFilterData('toDate', value)}
                                            style={{ width: '100%' }}
                                            placeholder={i18next.t("to_date")} />
                                    </Col>
                                    <Col span={6}>
                                        <FormLabelOptional label="&nbsp;" />
                                        <NJVButton
                                            loading={isSearchingData}
                                            size="large"
                                            style={{ width: '100%' }}
                                            type="primary" onClick={() => this.search()} text="Search" />
                                    </Col>
                                </Row>
                                <Row gutter={[24, 0]} justify="end">
                                    <Col span={6}>
                                        <FormLabelOptional label="&nbsp;" />
                                        <NJVButton size="large" loading={isDownloadExcel}
                                            onClick={() => this.downloadPartnershipReport()} type="primary" style={{ width: '100%', backgroundColor: 'green' }} text="Export Excel" />
                                    </Col>
                                </Row>
                            </NJVCard>
                        </Col>
                    </Row>
                    <Row gutter={[16, 16]} style={{ marginTop: '30px' }}>
                        <Col lg={6} md={8} xs={12} sm={12}>
                            <StatisticCard name="Total Revenue" value={totalRevenue} />
                        </Col>
                        <Col lg={6} md={8} xs={12} sm={12}>
                            <StatisticCard name="Total Commission" value={totalCommission} />
                        </Col>
                        <Col lg={6} md={8} xs={12} sm={12}>
                            <StatisticCard name="Total In-store Parcel" value={totalHandOnParcel} />
                        </Col>
                        <Col lg={6} md={8} xs={12} sm={12}>
                            <StatisticCard name="Total In Progress Parcel" value={totalOngoingParcel} />
                        </Col>
                    </Row>
                    <Row gutter={[16, 16]} style={{ marginTop: '30px' }}>
                        <Col lg={6} md={8} xs={12} sm={12}>
                            <StatisticCard name="Total Complete Parcel" value={totalCompleteParcel} />
                        </Col>
                        <Col lg={6} md={8} xs={12} sm={12}>
                            <StatisticCard name="Total Cancel Parcel" value={totalCancelParcel} />
                        </Col>
                        <Col lg={6} md={8} xs={12} sm={12}>
                            <StatisticCard name="Total Created Parcel" value={totalParcel} />
                        </Col>
                    </Row>
                    <Row style={{ marginTop: '20px' }}>
                        {
                            isFetchingData ?
                                <Skeleton active />
                                :
                                <Col span={24}>
                                    {/* {
                            storeReportsList.length !== 0 ? 
                            <>
                                {
                                totalHandOnParcel !== 0 ?
                                    <Col lg={12} md={12} xs={24} sm={24}> */}
                                    {/* {
                                                storeReportsList?.map((data, index) => (
                                                    <>
                                                        {data.handOnParcel === 0 ? <></> : 
                                                            <Row key={index} style={{ padding: '5px 0', borderRadius: '10px', textAlign: 'center', alignItems: 'center' }}>
                                                                <Col span={2}>{index}</Col>
                                                                <Col span={20} style={{ textAlign: 'start' }}>{data.storeName}</Col>
                                                                <Col span={2} style={{ fontWeight: 'bold', color: '#fff', backgroundColor: '#c2002f', borderRadius: '50px', padding: '2px' }}>{data.handOnParcel}</Col>
                                                            </Row>
                                                        }                                                
                                                    </>
                                                ))
                                                } */}
                                    {/* <NJVReportTable 
                                            columns={columns} 
                                            dataSource={storeReportsList} rowKey={record => record.id} rowClassName={() => "custom-row"} pagination={false} /> */}
                                    {/* <TotalDisplay title="Total Hand On Parcel" count={totalHandOnParcel} />
                                            <Table columns={handOnColumn} dataSource={handOn_list} pagination={false} rowKey={record => record.id} /> */}
                                    {/* ))} */}
                                    {/* </Col>
                                    : <></>
                            } */}
                                    {/* {
                                totalOngoingParcel !== 0 ?
                                    <Col lg={12} md={12} xs={24} sm={24}>
                                        <TotalDisplay title="Total Ongoing Parcel" count={totalOngoingParcel} />
                                        <Table
                                            columns={onGoingColumn}
                                            dataSource={onGoing_list} pagination={false} rowKey={record => record.id} />
                                    </Col>
                                    : <></>
                            }  
                        } */}
                                    <NJVReportTable
                                        columns={column}
                                        dataSource={data}
                                        rowKey={record => record.id}
                                        rowClassName={() => "custom-row"} 
                                        // rowClassName={getRowClassName}
                                        pagination={false} />
                                </Col>
                        }
                    </Row>
                </Col>
            </Row>
        )
    }
}

export default compose(withRouter)(AdminDashboardPage)

