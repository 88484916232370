const Theme = {
    colors: {
        primary: '#c2002f',
        secondary: '#f5f5f5',
        success: '#28a745',
        card_bg_color: '#f5f5f5'
    }
}

export const Colors = {
    darkGreen: '#006400',
    red: '#FF0000',
    primary: '#c2002f'
}

export default Theme;