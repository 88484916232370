import { Col, Row } from 'antd';
import Lottie from 'lottie-react';
import React, { Component } from 'react';
import NoDataFoundAnimation from '../../animate-lottie/lottie-not-data-found.json';

class Report extends Component {
  render() {
    return (
      <>
        <Row> 
          <Col span={1} />
          <Col span={22}>
            <Row style={{ marginTop: '180px'}}>
              <Col span={24} style={{ display: 'flex', justifyContent: 'center' }}>
                <Lottie
                    style={{ height: '200px' }}
                    animationData={NoDataFoundAnimation}
                    loop={true}
                    autoplay={true}
                />
              </Col>
              <Col span={24} style={{ textAlign: 'center', marginTop: 20 }}>
                  <div style={{ fontSize: 16, fontWeight: '600', marginBottom: 10 }}>
                      No report yet!
                  </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </>
    )
  }
}

export default Report