import { Button, Card, ConfigProvider, DatePicker, FloatButton, Input, Select, Table, Typography } from "antd";
import Theme from "./theme";

const { Title } = Typography;

export const FormLabel = (props) => {
    const { label } = props;
    return (
        <div style={{ marginBottom: 5 }}>
            <span style={{ color: "red" }}>*</span> <span>{label}</span>
        </div>

    );
}
export const NJVSpacer = ({ height, width }) => {
    return (
        <div style={{ height: height, width: width, display: 'inline-block' }}></div>
    )
}


export const FormLabelOptional = (props) => {
    const { label } = props;
    return (
        <div style={{ marginBottom: 5 }}>
            <span>{label}</span>
        </div>

    );
}

export const TitleLevel3 = (props) => {
    const { label,style } = props;
    return (
        <Title level={3} style={style}>{label}</Title>
    )
}

// export const NJVAddButton = ({ handleClick }) => {
//     return <Button
//         type="primary"
//         icon={<PlusCircleFilled />}
//         shape="round"
//         onClick={handleClick}
//     >
//         {`${i18next.t("create")}`}
//     </Button>
// }

export const NJVButton = ({ ...buttonProps }) => {
    return (
        <ConfigProvider theme={{
            token: {
                // colorPrimary: '#bf002e',
                borderRadius: '7px',
                borderRadiusLG: '9px'
            }
          }}>
            <Button
                {...buttonProps}
            >
                {buttonProps.text}
            </Button>
        </ConfigProvider>
  )
}

export const NJVFloatButton = ({...btnProps}) => {
    return (
        <ConfigProvider theme={{
            token: {
                margin: 12,
                fontSize: 12,
                fontSizeIcon: 10,
                paddingXXS: 2
            }
          }}>
            <FloatButton
                {...btnProps}
            >
                {btnProps.text}
            </FloatButton>
        </ConfigProvider>
    )
}

export const NJVInput = ({...inputProps}) => {
    return (
        <ConfigProvider theme={{
            token: {
                colorBgContainer: '#fff',
                colorBorder: '#fff',
                colorPrimary: '#fff',
                colorPrimaryHover: '#fff',
                controlHeight: '40px',
            },
            components: {
                Input: {
                    activeShadow: '0 0 0 1px rgb(128, 128, 128)',
                    borderRadius: '9px',
                    borderRadiusLG: '9px',
                    paddingBlock: '7px',
                }
            }
          }}>
            {
                inputProps.isPasswordInput ? <Input.Password {...inputProps} /> : <Input {...inputProps} />
            }
        </ConfigProvider>
    )
}

export const NJVSelect = ({children,...selectProps}) => {
    return (
        <ConfigProvider theme={{
            token: {
                colorBgContainer: '#fff',
                colorBorder: '#fff',
                colorPrimary: 'rgb(128, 128, 128)',
                colorPrimaryHover: '#fff',
                controlHeightLG: '40px',
            },
            components: {
                Select: {
                    borderRadius: '9px',
                    optionPadding: '4px 12px',
                    optionSelectedBg: 'rgb(128, 128, 128)',
                    optionSelectedColor: '#fff',
                    optionHeight: 30
                }
            }
          }}>
            <Select {...selectProps} >{children}</Select>
          </ConfigProvider>
    )
}

export const NJVTextarea = ({...textareaProps}) => {
    return (
        <ConfigProvider textArea={{ className: 'custom-textarea '}}>
            <Input.TextArea {...textareaProps} />
        </ConfigProvider>
    )
}

export const NJVDatePicker = ({...datepickerProps }) => {
    return (
        <ConfigProvider theme={{
            token: {
                colorBgContainer: '#fff',
                colorBorder: '#fff',
                colorPrimary: 'rgb(128, 128, 128)',
                colorPrimaryHover: '#fff',
                cellWidth: 36
            },
            components: {
                DatePicker: {
                    activeShadow: '0 0 0 1px rgb(128, 128, 128)',
                    borderRadius: '9px',
                    activeBorderColor: '#fff'
                },
            }
        }}>
            <DatePicker {...datepickerProps} />
        </ConfigProvider>
    )
}

export const NJVCard = ({children,...cardProps}) => {
    return (
        <ConfigProvider theme={{
            token: {
                colorBgContainer: Theme.colors.card_bg_color,
                boxShadowTertiary: 'none',
                padding: '10px',
                paddingLG: '10px',
            },
            components: {
                Card: {
                    headerHeight: 56
                }
            }
        }}>
            <Card {...cardProps}>{children}</Card>
        </ConfigProvider>
    )
}

export const NJVNoServiceCard = ({children,...cardProps}) => {
    return (
        <ConfigProvider theme={{
            token: {
                padding: '0',
                paddingLG: '0',
            },
        }}>
            <Card {...cardProps}>{children}</Card>
        </ConfigProvider>
    )
}

export const NJVReportCard = ({children,...cardProps}) => {
    return (
        <ConfigProvider theme={{
            token: {
                colorBgContainer: 'rgba(194, 0, 47, 0.1)',
                padding: '20px',
                paddingLG: '20px',
                borderRadiusLG: 20
            },
        }}>
            <Card {...cardProps}>{children}</Card>
        </ConfigProvider>
    )
}

export const NJVReportTable = ({...tableProps}) => {
    return (
        <ConfigProvider theme={{
            token: {
                colorBgContainer: "transparent"
            },
            components: {
                Table: {
                    // headerBg: 'transparent',
                    // rowHoverBg: 'rgba(194, 0, 47, 0.1)',
                    cellPaddingBlock: '10px',
                    borderColor: 'transparent'
                }
            }
        }}>
            <Table className="custom-table" {...tableProps}  />
        </ConfigProvider>
    )
}
