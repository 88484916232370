const shipper = '/v1'
const CustomPath = {
    app: '/',


    partnership_store: shipper + '/store',
    partnership_store_create: shipper + '/store/create',
    partnership_store_update: shipper + '/store/update',
    partnership_store_update_param: shipper + '/store/update/:id',

    partnership_store_admin_order_history: shipper + '/store/order-history',


    notification_history: shipper + '/notification-history',


    dashboard: shipper + '/dashboard',
    profile: shipper + '/profile',

    notification: shipper + '/notification',
    notification_detail: shipper + '/notification/detail',

    order: shipper + '/order',
    order_create: shipper + '/order_create',
    order_confirmation: shipper + '/order_create/order_confirmation',
    order_return: shipper + '/order_return',
    order_update: shipper + '/order_update',
    order_history: shipper + '/order_history',
    order_detail: shipper + '/order_detail',
    order_detail_param: shipper + '/order_detail/:id',
    order_awb: shipper + '/awb',
    rom_order: shipper + '/rom',
    rts_order: shipper + '/rts-order',
    rts_order_detail: shipper + '/rts-order/detial',

    user_address: shipper + '/address',
    user_address_create: shipper + '/address/create',
    user_address_update: shipper + '/address/update',
    user_address_update_param: shipper + '/address/update/:id',

    airwaybill: shipper + '/air-way-bill',


    terms_and_condition: shipper + '/terms-and-condition',
    privacy_and_policy: shipper + '/privacy_and_policy',
    privacy_and_policy_public: '/public/privacy_and_policy',
    guide: shipper + '/guide',

    single_order_result: shipper + '/single_order_detail',
    single_order_result_param: shipper + '/single_order_detail/:id',

    faq: shipper + '/faq',
    login: '/',
    logout: "/logout",
    signUp: "/signUp",
    forgot_password: "/forgot_password",

    invoice: shipper + '/invoice',
    report: shipper + '/report',

    none_service: shipper + '/none-service-item'
}

export default CustomPath;