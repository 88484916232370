import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import CustomPath from '../../routes/custom-path';

function RedirectLogin() {
  const navigate = useNavigate();
  useEffect(() => {
    navigate(CustomPath.login)
  }, [])
  return (
    <></>
  )
}

export default RedirectLogin


// class RedirectLogin extends Component {
//   componentDidMount() {
//     this.props.navigate(CustomPath.login)
//   }
//   render() {
//     return (
//       <></>
//     )
//   }
// }

// export default compose(withRouter)(RedirectLogin)